import React from 'react';
import { Modal, Form, Button, Row, Col, Card, Badge, OverlayTrigger, Tooltip,Dropdown } from 'react-bootstrap';
import { XCircleIcon,PlusIcon } from '@heroicons/react/solid';
import AttachmentDescriptionInput from "../../FormComponents/Inputs/AttachmentDescriptionInput";

const AttachmentFormModal = ({
  showModal,
  closeModal,
  attachmentsToAdd,
  removeCard,
  getFormValue,
  addItemInData,
  removeFile,
  addAttachment,
  availableTypes,
  addNewAttachment
}) => (
  <Modal as={Modal.Dialog} centered show={showModal} onHide={closeModal} size="lg">
    <Form className="modal-content p-3" onSubmit={(e) => e.preventDefault()}>
      <Modal.Header className="pb-0 border-0">
        <h5 className="fw-normal" >Add New Attachments</h5>
        <Button variant="close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body className="pb-0">
        {attachmentsToAdd.map((data, i) => (
          <Card border="primary" key={i} className="mb-2">
            <Card.Header className="d-flex justify-content-between">
              <div>
                <Badge bg="primary">{data.name}</Badge>
              </div>
              <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                <Card.Link className="ms-2" onClick={() => removeCard(data.id)}>
                  <XCircleIcon className="icon icon-xs text-danger" />
                </Card.Link>
              </OverlayTrigger>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="attachment_title">
                    <Form.Label>Attachment Title <span className="text-danger"> * </span></Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Type down a Title"
                      value={getFormValue(data.id, 'attachment_title')}
                      onChange={(e) => addItemInData(data.id, { attachment_title: e.target.value })}
                      maxLength={100}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="mb-3">
                  <Form.Group id="file">
                    <Form.Label>Choose File <span className="text-danger"> * </span></Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={(e) => addItemInData(data.id, { files: e.target.files })}
                      required
                    />
                    <ul>
                      {Array.from(getFormValue(data.id, 'files')).map((file, i) => (
                        <li key={i}>
                          {file.name}
                          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                            <Card.Link className="ms-2" onClick={(e) => removeFile(e, i, data.id)}>
                              <XCircleIcon className="icon icon-xs text-danger" />
                            </Card.Link>
                          </OverlayTrigger>
                        </li>
                      ))}
                    </ul>
                  </Form.Group>
                </Col>
                <Col md={12} className="mb-3">
                  <AttachmentDescriptionInput
                    description={getFormValue(data.id, 'description')}
                    onChange={(e) => addItemInData(data.id, { description: e.target.value })}
                    isDisabled={false}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}

        <Dropdown className={availableTypes.length === 0 ? 'd-none' : undefined}>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                <PlusIcon className="icon icon-xs me-2" /> Attachment
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {availableTypes.map((value) => (
                <Dropdown.Item
                    as={'div'}
                    key={value.id}
                    onClick={() => addNewAttachment(value.name, value.id)}
                >
                    {value.name}
                </Dropdown.Item>
                ))}
            </Dropdown.Menu>
            </Dropdown>
         <Col md={12} className="mb-3">
            <div className="d-grid pt-3">
            {attachmentsToAdd.length > 0 && (
                <Button variant="primary" onClick={addAttachment}>
                    Save Attachments
                </Button>
            )}
            </div>
          </Col>
      </Modal.Body>
      <Modal.Footer className="justify-content-start border-0 pt-0"></Modal.Footer>
    </Form>
  </Modal>
);

export default AttachmentFormModal;
