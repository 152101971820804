import React from "react";
import { Row } from "react-bootstrap";
import {GeneralStoreInfoForm } from "../../../components/Forms";

  export  const NewStoreForm = () => {
    return (
      <>
        <Row>
          <GeneralStoreInfoForm />
        </Row>
      </>
    );
  };





