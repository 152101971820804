import React from "react";
import { Row } from "react-bootstrap";
import {GeneralProspectInfoForm } from "../../../components/Forms";

export const NewProspectForm = () => {
  return (
    <>
      <Row>
        <GeneralProspectInfoForm />
      </Row>
    </>
  );
};
