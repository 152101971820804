import React, { useState, useEffect } from 'react';
import { Alert,Col,Row } from 'react-bootstrap';
import { ProspectsMetricsOverViewWidget, StoresMetricsOverViewWidget, BranchesMetricsOverViewWidget, TeamMembersWidget,TimelineWidget} from "components/Widgets";

export default () => {
    const [welcomeMessage, setWelcomeMessage] = useState("");
    useEffect(() => {
      const storedMessage = localStorage.getItem("welcomeMessage");
      if (storedMessage) {
        setWelcomeMessage(storedMessage);
        localStorage.removeItem("welcomeMessage");
      }
    }, []);

    return (
    <>
        <div className="dashboard-container">
            {welcomeMessage && (
                <Alert variant="success">
                    {welcomeMessage}
                </Alert>
            )}
            <h6>Dashboard</h6>
            <Row>
                <Col xs={12} sm={4} xl={4} className="mb-4">
                    <StoresMetricsOverViewWidget category="Stores" />
                    <BranchesMetricsOverViewWidget category="Branches" />
                </Col>
                <Col xs={12} sm={8} xl={8} className="mb-4">
                    <ProspectsMetricsOverViewWidget category="Prospects" />
                </Col>
                </Row>

            <Row>
                <Col xs={12} sm={6} xl={6} className="mb-4">
                    <TimelineWidget
                        title="Unfinished notes from the last 7 days"
                        userName = {localStorage.getItem('userName')}
                    />
                </Col>

                <Col xs={12} sm={6} xl={6} className="mb-4">
                    <TeamMembersWidget />
                </Col>
            </Row>
        </div>
    </>
    );
  }
