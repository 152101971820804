import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import AttachmentCard from './AttachmentCard';

const AttachmentList = ({ attachments, removeAttachment }) => (
  attachments.length > 0 && (
    <Card className="mt-4">
      <Card.Header className="bg-primary text-white">Selected Attachments</Card.Header>
      <Card.Body>
        <Row xs={12} sm={6} md={4} lg={3} xl={2} className="g-3">
          {attachments.map((attachment, index) => (
            <Col key={index} className="d-flex">
              <AttachmentCard
                attachment={attachment}
                removeAttachment={removeAttachment}
                index ={index}
              />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  )
);

export default AttachmentList;
