import React from 'react';
import { Card, Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
const AttachmentCard = ({ attachment, removeAttachment, index }) => (
  <Card className="w-100  position-relative">
    <Button
      variant="close"
      onClick={() => removeAttachment(index)}
      className="position-absolute top-0 end-0 m-1"
    />
    <Card.Body className="d-flex flex-column">
      <Badge bg="primary" >{attachment.type}</Badge>
      <ul>
        {attachment.files.map((file, i) => (
          <li key={i}><small style={{ fontSize: '10px' }}>{file.name}</small></li>
        ))}
      </ul>
    </Card.Body>
  </Card>
);

export default AttachmentCard;
