import React, { useState, useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';
import '../../../scss/permissions.scss';

export const SetPermissionsInput = ({
  permissions,
  selectedPermissions = [],
  handleChangePermissions,
  label,
  isEditMode = false
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [currentPermissions, setCurrentPermissions] = useState(selectedPermissions);

  useEffect(() => {
    setCurrentPermissions(selectedPermissions)
  }, [selectedPermissions]);

  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    if (newSelectAll) {
      const allPermissions = permissions;
      setCurrentPermissions(allPermissions);
      allPermissions.forEach((permission) => handleChangePermissions(permission, true));
    } else {
      setCurrentPermissions([]);
      permissions.forEach((permission) => handleChangePermissions(permission, false));
    }
  };

  const handlePermissionChange = (permission, isSelected) => {
    setCurrentPermissions((prev) => {
      const updatedPermissions = isSelected
        ? [...prev, permission]
        : prev.filter((p) => p !== permission);
      return updatedPermissions;
    });
    handleChangePermissions(permission, isSelected);
  };

  const formatPermissionLabel = (permission) => {
    return permission.replace(/([A-Z])/g, ' $1').toLowerCase();
  };

  return (
    <Col md={3} className="my-4">
      <Form.Group id={`${label}`}>
        <div className="d-flex align-items-center mb-3">
          <Form.Check
            type="checkbox"
            checked={selectAll}
            onChange={toggleSelectAll}
            className="select-all-checkbox"
          />
          <Form.Label className="ms-2">{label}</Form.Label>
        </div>
        <ul className="permission-tree">
          {permissions.map((permission) => (
            <li key={permission} className="permission-item">
              <Form.Check
                type="checkbox"
                id={`permission-${permission}`}
                label={formatPermissionLabel(permission)}
                checked={currentPermissions.includes(permission)}
                onChange={() => handlePermissionChange(permission, !currentPermissions.includes(permission))}
                custom
                className="permission-checkbox"
              />
            </li>
          ))}
        </ul>
      </Form.Group>
    </Col>
  );
};
