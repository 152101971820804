import React, { useState, useEffect } from 'react';
import { Form} from 'react-bootstrap';
import api from '../../../service/api';
import { BASE_URL } from '../../../helpers/config';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import SelectAttachmentType from './SelectAttachmentType';
import AttachmentFormModal from './AttachmentFormModal';
import AttachmentList from './AttachmentList';

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray',
  },
  buttonsStyling: false,
}));

const SetAttachments = ({ attachments, setAttachments }) => {
  const [attachmentTypes, setAttachmentTypes] = useState([]);
  const [attachmentsToAdd, setAttachmentsToAdd] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [availableTypes, setAvailableTypes] = useState([]);
  const [isSelectingType, setIsSelectingType] = useState(false);

  useEffect(() => {
    const fetchAttachmentTypes = async () => {
      try {
        const response = await api.get(`${BASE_URL}/attachmentTypes`);
        setAttachmentTypes(response.data.data);
        setAvailableTypes(response.data.data);
      } catch (error) {
        console.error(error);
        SwalWithBootstrapButtons.fire("Error", "Failed to load attachment types.", "error");
      }
    };

    fetchAttachmentTypes();
  }, []);

  const addNewAttachment = (type, id) => {
    setAttachmentsToAdd(prev => [
      ...prev,
      { id: Date.now(), name: type, attachment_type_id: id, attachment_title: '', description: '', files: [] }
    ]);
    setAvailableTypes(prev => prev.filter(item => item.name !== type));
    setShowModal(true);
    setIsSelectingType(false);
  };

  const getFormValue = (id, field) => {
    const attachment = attachmentsToAdd.find(item => item.id === id);
    return attachment ? attachment[field] : '';
  };

  const addItemInData = (id, data) => {
    setAttachmentsToAdd(prev =>
      prev.map(item => (item.id === id ? { ...item, ...data } : item))
    );
  };

  const removeCard = (id) => {
    const attachment = attachmentsToAdd.find(item => item.id === id);
    if (attachment) {
      setAvailableTypes(prev => [...prev, { name: attachment.name, label: attachment.name }]);
    }
    setAttachmentsToAdd(prev => prev.filter(item => item.id !== id));
    if(attachmentsToAdd.length === 1) {
        return setShowModal(false);
    }
  };

const addAttachment = () => {
    let filesValid = true;
    let missingFields = false;
    const isValid = attachmentsToAdd.every(field => {
      const files = Array.from(field.files);

      const isFilesValid = files.every(file => {
        const allowedTypes = ['application/msword', 'application/pdf', 'text/csv', 'application/vnd.ms-excel', 'image/jpeg', 'image/png', 'image/heic'];
        const maxSize = 10240; // 10MB em KB

        const fileTypeValid = allowedTypes.includes(file.type);
        const fileSizeValid = file.size <= maxSize * 1024;

        if(!fileTypeValid || !fileSizeValid) {
            SwalWithBootstrapButtons.fire(
                "Invalid Files",
                `<p>Some files have invalid types or exceed the 10MB size limit. Please check your files.</p>
                 <p><strong>Valid file extensions:</strong> .doc, .pdf, .csv, .xls, .jpeg, .png, .heic</p>`,
                "error"
            );
            filesValid = false;
            filesValid = false;
        }
        return fileTypeValid && fileSizeValid;
      });

      if (!field.attachment_title || !field.description || field.files.length === 0) {
        missingFields = true;
      }

      return isFilesValid && !missingFields;
    });

    if (filesValid && !missingFields) {
      const newAttachments = attachmentsToAdd.map(field => ({
        type: field.name,
        type_id: field.attachment_type_id,
        title: field.attachment_title,
        description: field.description,
        files: Array.from(field.files),
      }));

      setAttachments(prev => [...prev, ...newAttachments]);
      setShowModal(!showModal);
      setAttachmentsToAdd([]);
    } else {
      if (filesValid && missingFields) {
        SwalWithBootstrapButtons.fire(
          "Warning",
          "Please fill all fields and ensure file types and sizes are correct.",
          "warning"
        );
      }
    }
  };

  const closeModal = () => {
    attachmentsToAdd.forEach(attachment => {
      setAvailableTypes(prev => [...prev, { name: attachment.name, label: attachment.name }]);
    });
    setShowModal(false);
    setAttachmentsToAdd([]);
  };

  const removeAttachment = (index) => {
    const attachmentToRemove = attachments[index];
    setAvailableTypes((prev) => [
      ...prev,
      { name: attachmentToRemove.type, id: attachmentToRemove.type_id },
    ]);
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const removeFile = (e, fileIndex, attachmentId) => {
    const attachment = attachmentsToAdd.find(item => item.id === attachmentId);
    const updatedFiles = Array.from(attachment.files).filter((_, i) => i !== fileIndex);
    addItemInData(attachmentId, { files: updatedFiles });
  };
  return (
    <>
      <Form.Group className="mb-3" id="attachmentTypeSelect">
        <SelectAttachmentType availableTypes={availableTypes} addNewAttachment={addNewAttachment} />
      </Form.Group>

      <AttachmentFormModal
        showModal={showModal}
        closeModal={closeModal}
        attachmentsToAdd={attachmentsToAdd}
        removeCard={removeCard}
        getFormValue={getFormValue}
        addItemInData={addItemInData}
        removeFile={removeFile}
        addAttachment={addAttachment}
        availableTypes={availableTypes}
        addNewAttachment={addNewAttachment}
      />

      <AttachmentList attachments={attachments} removeAttachment={removeAttachment} />
    </>
  );
};

export default SetAttachments;
