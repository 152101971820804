import React from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';

const SelectServiceFrequency = ({ formData, handleChange, mode }) => {
    return (
        <>
            <Col md={3} className="mb-3">
                <Form.Group id="serviceFreqNumber">
                    <Form.Label>Service Frequency</Form.Label>
                    <InputGroup>
                        <Form.Select
                            name="serviceFreqNumber"
                            value={formData.serviceFreqNumber}
                            onChange={mode !== 'view' ? handleChange : undefined} // Prevent changes in view mode
                            disabled={mode === 'view'} // Disable in view mode
                        >
                            {Array.from({ length: 7 }, (_, i) => (
                                <option key={i + 1} value={`${i + 1}x`}>
                                    {`${i + 1}x`}
                                </option>
                            ))}
                        </Form.Select>
                    </InputGroup>
                </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
                <Form.Group id="serviceFreqFormat">
                    <Form.Label>Frequency Unit</Form.Label>
                    <InputGroup>
                        <Form.Select
                            name="serviceFreqFormat"
                            value={formData.serviceFreqFormat}
                            onChange={mode !== 'view' ? handleChange : undefined} // Prevent changes in view mode
                            disabled={mode === 'view'} // Disable in view mode
                        >
                            <option value="Day">Day</option>
                            <option value="Week">Week</option>
                            <option value="Month">Month</option>
                            <option value="Year">Year</option>
                        </Form.Select>
                    </InputGroup>
                </Form.Group>
            </Col>
        </>
    );
};

export default SelectServiceFrequency;
