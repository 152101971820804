
import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { RoutesPath } from "../../routes";
import { UsersTable } from "components/Tables";
import { BASE_URL } from '../../helpers/config';
import api from '../../service/api';
import { getPermissions } from "service/authService";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  const selectedUsersIds = users.filter(u => u.isSelected).map(u => u.id);
  const totalUsers = users.length;
  const allSelected = selectedUsersIds.length === totalUsers;
  const permissions = getPermissions();


//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await api.get(`${BASE_URL}/users`);
//         let data = Object.values(response.data.data);
//         const modifiedUsers = data.map(u => ({...u, isSelected: false, show: true }));
//         setUsers(modifiedUsers);

//       } catch (error) {
//       }
//     };
//
//     fetchData();
//   }, []);


const fetchData = async () => {
    try {
    const response = await api.get(`${BASE_URL}/users`);
    let data = Object.values(response.data.data);

    const modifiedUsers = data.map(u => ({...u, isSelected: false, show: true }));
    setUsers(modifiedUsers);

    } catch (error) {
    console.log(error);
    await SwalWithBootstrapButtons.fire({
        icon: 'error',
        title: 'Error loading users',
        text: 'Unable to load users. Please try again later.',
      });
    }
};

useEffect(() => {
fetchData();
}, []);

//   const changeSearchValue = (e) => {

//     const newSearchValue = e.target.value.toLowerCase();
//     const newUsers = users.map(u => ({
//         ...u,
//         show: u.name.toLowerCase().includes(newSearchValue) ||
//               u.created_at.toLowerCase().includes(newSearchValue) ||
//               u.phone_number.toLowerCase().includes(newSearchValue)
//     }));

//     setSearchValue(newSearchValue);
//     setUsers(newUsers);
// };

const changeSearchValue = (e) => {
    const newSearchValue = e.target.value.toLowerCase();

    // Primeiro, filtra pelo status selecionado
    const newUsers = users.map(u => {
      const statusMatches = statusValue === "all" || u.status.toString() === statusValue;

      // Agora, verifica se o nome ou qualquer outro campo de pesquisa corresponde
      const searchMatches = u.name.toLowerCase().includes(newSearchValue) ||
                            u.created_at.toLowerCase().includes(newSearchValue) ||
                            u.phone_number.toLowerCase().includes(newSearchValue);

      // O usuário será mostrado apenas se ambos os filtros forem satisfeitos
      return {
        ...u,
        show: statusMatches && searchMatches
      };
    });

    setSearchValue(newSearchValue);
    setUsers(newUsers);
  };

  const changeStatusValueFilter = (e) => {
    const newStatusValue = e.target.value;
    const numericStatusValue = newStatusValue === "all" ? "all" : parseInt(newStatusValue, 10);

    const newUsers = users.map(p => ({
        ...p,
        show: numericStatusValue === "all" || p.status === numericStatusValue
    }));

    setStatusValue(newStatusValue);
    setUsers(newUsers);
  };

  const selectAllUsers = () => {
    const newUsers = selectedUsersIds.length === totalUsers ?
      users.map(u => ({ ...u, isSelected: false })) :
      users.map(u => ({ ...u, isSelected: true }));

    setUsers(newUsers);
  };

  const selectUser = (id) => {
    const newUsers = users.map(u => u.id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setUsers(newUsers);
  };

//   const deleteUser = async (id) => {
//     const result = await SwalWithBootstrapButtons.fire({
//       icon: "error",
//       title: "Are you sure?",
//       text: "Do you really want to delete this record?",
//       showCancelButton: true,
//       confirmButtonText: "Yes",
//       cancelButtonText: "Cancel"
//     });

//     if (result.isConfirmed) {
//       try {
//         await api.delete(`${BASE_URL}/user/${id}`);
//       } catch (error) {
//         console.error('Error submitting form:', error);
//       }
//       const newUsers = users.filter(f => f.id !== id);
//       const confirmMessage = "The user has been deleted.";

//       setUsers(newUsers);
//       await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
//     }
//   };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>Users</Breadcrumb.Item>
            <Breadcrumb.Item active>All Users</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Users</h4>
          <p className="mb-0">Manage Users</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          { permissions.includes('createUser') &&
            <Button variant="gray-800" size="sm" className="d-inline-flex align-items-center" as={Link} to={RoutesPath.NewUserForm.path}>
              <PlusIcon className="icon icon-xs me-2" /> New User
            </Button>
          }
          <ButtonGroup className="ms-2 ms-lg-3">
            <Button variant="outline-gray-600" size="sm">Import</Button>
            <Button variant="outline-gray-600" size="sm">Export</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search users"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusValue} className="fmxw-200 d-none d-md-inline" onChange={changeStatusValueFilter}>
              <option value="all">All</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </Form.Select>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <ButtonGroup>
              <Dropdown className="me-1">
                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                  <CogIcon className="icon icon-sm" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Show
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold">
                    10 <CheckIcon className="icon icon-xxs ms-auto" />
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      </div>

      <UsersTable
        users={users.filter(u => u.show)}
        allSelected={allSelected}
        selectUser={selectUser}
        // deleteUser={deleteUser}
        selectAllUsers={selectAllUsers}
        refreshUsers={fetchData}
      />
    </>
  );
};
