import React, { useState } from "react";
import axios from 'axios';
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RoutesPath } from "routes";
import { BASE_URL } from '../helpers/config';
import { useNavigate } from 'react-router-dom';

export default () => {
    const [identifier, setIdentifier] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const navigate = useNavigate();
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const request = { email: identifier };

        try {
            const resp = await axios.post(`${BASE_URL}/forgot-password`, request);
            // const token = resp.data.token;
            const email = resp.data.email;
            if (resp.data.email) {
                setMessage("We’ve sent an email with a link to reset your password!");
                setMessageType("success");
            } else {
                setMessage("Something went wrong. Please try again.");
                setMessageType("danger");
            }
        } catch (error) {
            console.error('Error while logging in:', error);
            const backendMessage = error.response?.data?.message;
            const errorMessage = backendMessage || "There was an error sending the reset email. Please try again.";
            setMessage(errorMessage);
            setMessageType("danger");
        } finally {
            setLoading(false);
        }
    };

  const handleCapsLock = (e) => {
    if (e.getModifierState("CapsLock")) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

    return (
        <main>
            <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <p className="text-center">
                            <Card.Link as={Link} to={RoutesPath.Signin.path} className="d-flex align-items-center justify-content-center">
                                <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to sign in
                            </Card.Link>
                        </p>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="signin-inner my-3 my-lg-0 bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center mb-4">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/logo/Logo-MQM-Official-Mobile-08-2021.svg`}
                                        alt="Logo"
                                        className="img-fluid"
                                        style={{ maxWidth: '90px' }}
                                    />
                                </div>

                                <h5 className="text-center">Forgot your password?</h5>
                                <p className="mb-4 text-center">Don't fret! Just type in your email and we will send you a link to reset your password!</p>

                                {message && (
                                    <Alert
                                     variant={messageType}
                                     className="mb-3"
                                     style={{ padding: '5px 10px', margin: '0 auto', maxWidth: '400px', fontSize: '14px' }}  // Font size ajustado
                                     >
                                        {message}
                                    </Alert>
                                )}

                                <Form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <Form.Label htmlFor="email">Your Email</Form.Label>
                                        <InputGroup id="email">
                                            <Form.Control
                                                autoFocus
                                                required
                                                type="text"
                                                value={identifier}
                                                onChange={(e) => setIdentifier(e.target.value)}
                                                placeholder="Your Email"
                                                onKeyDown={handleCapsLock}
                                                onKeyUp={handleCapsLock}
                                                maxLength={100}
                                            />
                                        </InputGroup>
                                        {isCapsLockOn && (
                                            <div className="mb-3 text-primary">
                                             <span style={{ fontSize: '12px' }}><strong>Warning: Caps Lock is ON!</strong></span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="d-grid">
                                        <Button variant="gray-800" type="submit" disabled={loading}>
                                            {loading ? 'Sending...' : 'Recover password'}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
