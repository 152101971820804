import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Col, Row, Form, Button, InputGroup } from 'react-bootstrap';
import { AttachmentCardWidget } from "components/Widgets";
import { ArchiveIcon, PlusIcon, FolderOpenIcon, ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import { CreateNewAttachmentModel } from "components/Modals";
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../helpers/config';
import { Tooltip, ButtonGroup, OverlayTrigger } from 'react-bootstrap';
import api from './../service/api';
import Dropdown from 'react-bootstrap/Dropdown';
import { getPermissions } from "service/authService";

const ArchiveIconHtml = ReactDOMServer.renderToString(
  <ArchiveIcon className="h-50 w-auto" />
);

const SwalWithBootstrapButtons = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-3",
      cancelButton: "btn btn-gray",
    },
    buttonsStyling: false,
  })
);

const AttachmentsComponent = () => {
  const [attachments, setAttachments] = useState([]);
  const [allAttachments, setAllAttachments] = useState([]);
  const { entity, entityId } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [showCreateCardModal, setShowCreateCardModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [attachmentTypes, setAttachmentTypes] = useState([]);
  const [attachmentType, setAttachmentType] = useState({});

  let permissions = getPermissions();

  useEffect(() => {
      const fetchAttachmentTypes = async () => {
          try {
              const response = await api.get(`${BASE_URL}/attachmentTypes`);
              setAttachmentTypes(Object.values(response.data.data));
          } catch (error) {
              console.error(error);
              await SwalWithBootstrapButtons.fire(
                  "Error loading attachment types",
                  error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue loading the attachment types. Please try again later.",
                  "error"
              );
          }
      };

      fetchAttachmentTypes();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`${BASE_URL}/attachments/${entity}/${entityId}`);
        let data = Object.values(response.data.data);
        const modifiedAttachments = data.map(n => ({ ...n, isSelected: false, show: true }));
        setAttachments(modifiedAttachments);
        setAllAttachments(modifiedAttachments);
      } catch (error) {
        console.log(error);
        await SwalWithBootstrapButtons.fire(
            "Error loading attachment(s)",
            error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue loading the attachments. Please try again later.",
            "error"
          );
      }
    };

    if (entity && entityId) {
      fetchData();
    }
  }, [entity, entityId]);

  const fetchAttachments = async () => {
    try {
      const response = await api.get(`${BASE_URL}/attachments/${entity}/${entityId}`);
      const data = Object.values(response.data.data);
      const modifiedAttachments = data.map(a => ({ ...a, isSelected: false, show: true }));
      setAttachments(modifiedAttachments);
      setAllAttachments(modifiedAttachments);
    } catch (error) {
      console.error(error);
      await SwalWithBootstrapButtons.fire(
        "Error loading attachment(s)",
        error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue loading the attachments. Please try again later.",
        "error"
      );
    }
  };

  const toggleCreateCardModal = () => {
    setShowCreateCardModal(!showCreateCardModal);
  };

  useEffect(() => {
    const handleAttachmentSelection = () => {
      const selectedIds = attachments.filter(a => a.isSelected).map(a => a.status);
      const hasArchived = selectedIds.includes("archived");
      const hasAvailable = selectedIds.includes("available");
      if (hasArchived && hasAvailable) {
        setMessage('You cannot archive or restore attachments when both types are selected.');
        setShowAlert(true);
      } else {
        setMessage('');
        setShowAlert(false);
      }
    };

    handleAttachmentSelection();
  }, [attachments]);

  const archiveSelectedAttachments = async () => {
    const selectedAttachmentsIds = attachments.filter(a => a.isSelected).map(a => a.id);
    const attachmentsNr = selectedAttachmentsIds.length;
    const textMessage = attachmentsNr === 1 ? "Do you really want to archive this attachment?" : "Do you really want to archive these attachments?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      iconHtml: ArchiveIconHtml,
      title: "Are you sure?",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await api.put(`${BASE_URL}/attachments/status`, {
          ids: selectedAttachmentsIds,
          status: "archived"
        });

        await fetchAttachments();

        const confirmMessage = attachmentsNr === 1 ? "The attachment has been archived." : "The attachments have been archived.";
        await SwalWithBootstrapButtons.fire("Archived", confirmMessage, "success");

      } catch (error) {
        console.error('Error submitting form:', error);
        await SwalWithBootstrapButtons.fire(
            "Error updating the attachment(s)",
            error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue updating the attachment(s) status. Please try again later.",
            "error"
          );
      }
    }
  };

  const restoreSelectedAttachments = async () => {
    const selectedAttachmentsIds = attachments.filter(a => a.isSelected).map(a => a.id);
    const AttachmentsNr = selectedAttachmentsIds.length;
    const textMessage = AttachmentsNr === 1 ? "Do you really want to restore this attachment?" : "Do you really want to restore these attachments?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      iconHtml: ArchiveIconHtml,
      title: "Are you sure?",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await api.put(`${BASE_URL}/attachments/status`, {
          ids: selectedAttachmentsIds,
          status: "available"
        });

        await fetchAttachments();

        const confirmMessage = AttachmentsNr === 1 ? "The attachment has been restored to its original status." : "The attachments have been restored to their original status.";

        await SwalWithBootstrapButtons.fire("Restored.", confirmMessage, "success");
      } catch (error) {
        console.error('Error submitting form:', error);
        await SwalWithBootstrapButtons.fire(
          "Error updating attachment(s)",
          error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue updating the note status. Please try again later.",
          "error"
        );
      }
    }
  };

  const selectAttachment = (groupId, isSelected) => {
    setAttachments((prevAttachments) =>
      prevAttachments.map((a) =>
        a.id === groupId ? { ...a, isSelected } : a
      )
    );
  };

  const toggleStatus = async (attachmentId_id, status) => {
    try {
      const newStatus = status.includes("Archived") ? "archived" : "available";
      const result =  await SwalWithBootstrapButtons.fire({
        icon: "question",
        iconHtml: ArchiveIconHtml,
        title: "Are you sure?",
        text: "Do you really want to proceed with this action?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        await api.put(`${BASE_URL}/attachments/status`, {
            ids: [attachmentId_id],
            status: newStatus
          });

          await fetchAttachments();

          if (newStatus === "available") {
            await SwalWithBootstrapButtons.fire(
                "Attachment successfully restored to its original status!",
                "",
                "success"
            );
        } else {
            await SwalWithBootstrapButtons.fire(
                `Successfully marked as ${newStatus}`,
                "",
                "success"
            );
        }
      }

    } catch (error) {
      console.error('Error updating status:', error);
      await SwalWithBootstrapButtons.fire(
        "Error updating attachment",
         error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue updating the attachment status. Please try again later.",
        "error"
    );
    }
  };

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value.toLowerCase();
    setSearchValue(newSearchValue);
    const filteredAttachments = allAttachments.filter((a) => {
      const status = a.status.toLowerCase();
      const description = a.attachment_description.toLowerCase();
      const created_at = a.created_at.toLowerCase();
      const attachment_type = a.attachment_type.name.toLowerCase();
      // const attachment_name = a.attachments.name.toLowerCase();
      const attachment_title = a.attachment_title.toLowerCase();
      const user = a.user.name.toLowerCase();
      return (
        status.includes(newSearchValue) ||
        description.includes(newSearchValue) ||
        created_at.includes(newSearchValue) ||
        attachment_type.includes(searchValue) ||
        user.includes(newSearchValue) ||
        // attachment_name.includes(newSearchValue) ||
        attachment_title.includes(newSearchValue) ||
        `${a.time}`.includes(newSearchValue)
      );
    });

    setAttachments(filteredAttachments);
    setCurrentPage(1);
  };

  const selectedAttachmentsIds = attachments.filter(a => a.isSelected).map(a => a.attachmentId);
  const disableMenu = selectedAttachmentsIds.length === 0;

  // Pagination
  const indexOfLastAttachment = currentPage * itemsPerPage;
  const indexOfFirstAttachment = indexOfLastAttachment - itemsPerPage;
  const currentAttachments = attachments.slice(indexOfFirstAttachment, indexOfLastAttachment);
  const totalPages = Math.ceil(attachments.length / itemsPerPage);

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    setMessage(''); // Clear the message if needed
    setAttachments(prevAttachments =>
        prevAttachments.map(a => ({ ...a, isSelected: false }))
      );
  };

  function addAndShowModalAttachment(data)  {
    setAttachmentType(data)
    toggleCreateCardModal()
  }

  return (
    <>
      {showAlert && (
        <div className="alert alert-warning d-flex justify-content-between align-items-center">
          <span>{message}</span>
          <button className="btn-close" onClick={handleAlertClose} aria-label="Close"></button>
        </div>
      )}
      {showCreateCardModal && (
        <CreateNewAttachmentModel
          show={showCreateCardModal}
          onHide={toggleCreateCardModal}
          entityName={entity}
          entityId={entityId}
          refreshAttachments={fetchAttachments}
          attachmentTypes={attachmentTypes}
          attachmentType={attachmentType}
        />
      )}

      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
          <div className="me-lg-3">
          { permissions.includes('createAttachment') &&
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              <PlusIcon className="icon icon-xs me-2" /> Attachment
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {attachmentTypes.map((value) => (
                  <Dropdown.Item
                    as={'button'}
                    key={value.id}
                    onClick={() => addAndShowModalAttachment(value)}
                  >{value.name}</Dropdown.Item>

                ))}
              </Dropdown.Menu>
            </Dropdown>
          }
          </div>
          <ButtonGroup>
            {!showAlert && permissions.includes('updateStatusAttachment') && (
              <>
                <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-0">Archive</Tooltip>}>
                  <Button
                    variant="gray-800"
                    disabled={disableMenu || !attachments.some(a => a.isSelected && a.status === "available")}
                    onClick={archiveSelectedAttachments}
                  >
                    <ArchiveIcon className="icon icon-xs" />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-0">Restore</Tooltip>}>
                  <Button
                    variant="gray-800"
                    disabled={disableMenu || !attachments.some(a => a.isSelected && a.status === "archived")}
                    onClick={restoreSelectedAttachments}
                  >
                    <FolderOpenIcon className="icon icon-xs" />
                  </Button>
                </OverlayTrigger>
              </>
            )}
          </ButtonGroup>
        </Col>

        <Col xs={12} lg={6}>
          <InputGroup className="d-flex justify-content-lg-end">
            <Form.Control
              type="text"
              placeholder="Search for attachments here"
              className="w-100 fmxw-300"
              value={searchValue}
              onChange={changeSearchValue}
            />
          </InputGroup>
        </Col>
      </div>

      <div className="task-wrapper border bg-white border-light shadow-sm py-1 rounded">
        {currentAttachments.filter((a) => a.show).map((a) => (
          <AttachmentCardWidget
            {...a}
            key={`attachment-${a.attachmentId}`}
            selectAttachment={selectAttachment}
            toggleStatus={toggleStatus}
          />
        ))}

        <Row className="d-flex align-items-center p-4">
          <Col xs={7} className="mt-1">
            Showing {indexOfFirstAttachment + 1} - {Math.min(indexOfLastAttachment, attachments.length)} of {attachments.length}
          </Col>
          <Col xs={5}>
            <ButtonGroup className="float-end">
              <Button variant="light" onClick={prevPage} disabled={currentPage === 1}>
                <ChevronLeftIcon className="icon icon-xs" />
              </Button>
              <Button variant="primary" onClick={nextPage} disabled={currentPage >= totalPages}>
                <ChevronRightIcon className="icon icon-xs" />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AttachmentsComponent;
