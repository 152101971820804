
import React from "react";
import ApexChart from "react-apexcharts";


export const RevenueChart = ({ labels, data, name}) => {
  const chartSeries = [{
    name: name,
    data: data,
  }];

  const chartOptions = {
    chart: {
      sparkline: {
        enabled: true
      }
    },
    theme: {
      monochrome: {
        enabled: true,
        color: '#31316A',  // Cor do gráfico
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '25%',
        borderRadius: 5,
        radiusOnLastStackedBar: true,
        colors: {
          backgroundBarColors: ['#F2F4F6', '#F2F4F6', '#F2F4F6', '#F2F4F6'],
          backgroundBarRadius: 5,
        },
      }
    },
    xaxis: {
      categories: labels,
      crosshairs: {
        width: 1
      },
    },
    tooltip: {
      fillSeriesColor: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      theme: 'light',
      style: {
        fontSize: '12px',
        fontFamily: 'Inter',
      },
    },
  };

  return (
    <ApexChart
      type="bar"  // Tipo de gráfico, você pode mudar para 'line' ou outro
      height={140}
      width="100%"
      series={chartSeries}  // Passando os dados para a série do gráfico
      options={chartOptions}  // Passando as opções de configuração
    />
  );
};

