import React, { useState,useEffect } from 'react';
import { OfficeBuildingIcon,CheckCircleIcon, ClockIcon, DotsHorizontalIcon, DownloadIcon,CollectionIcon,LocationMarkerIcon,ArchiveIcon,ChevronLeftIcon, ChevronRightIcon, ArrowSmRightIcon} from "@heroicons/react/solid";
import { Col, Card, Form, Badge, Button, Dropdown,Row,ListGroup,Spinner} from 'react-bootstrap';
import api from '../service/api';
import { BASE_URL } from '../helpers/config';
import{ModalDescription, ModalDescriptionAttachments} from "components/Modals";
import { getPermissions } from 'service/authService';
import { RevenueChart } from "components/Charts";

export const NoteCardWidget = (props) => {
    const { note_id, note_title, user_name, created_at, status, description, isSelected } = props;
    const formCheckId = `note-${note_id}`;

    const statusEntity = {
        done: { color: "success", label: "Done" },
        archived: { color: "warning", label: "Archived" },
        registered: { color: "primary", label: "Registered" }
    };

    const currentStatus = statusEntity[status] || statusEntity.registered;
    const statusColor = currentStatus.color;
    const statusLabel = currentStatus.label;

    const toggleStatus = (newStatus) => {
        props.toggleStatus && props.toggleStatus(note_id, newStatus);
    };

    const selectNote = (e) => {
        props.selectNote && props.selectNote(note_id, e.target.checked);
    };
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    let permissions = getPermissions();

    return (
    <>
        <Card border="bottom" className="hover-state rounded-0 rounded-top py-3">
            <Card.Body className="d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0">
                { permissions.includes('updateStatusNote') &&
                    <Col xs={1} className="text-left text-sm-center mb-2 mb-sm-0">
                        <Form.Check type="checkbox" className="form-check check-lg inbox-check me-sm-2">
                            <Form.Check.Input note_id={formCheckId} checked={isSelected} onChange={selectNote} />
                        </Form.Check>
                    </Col>
                }
                <Col xs={11} lg={10} className="px-0 mb-4 mb-md-0">
                    <div className="mb-2">
                        <div className="d-block d-sm-flex">
                            <div>
                                <h6 className="fw-normal text-gray mb-3 mb-sm-0">
                                    <ClockIcon className="icon icon-xxs text-gray-500 me-2" /> {created_at} - {user_name}
                                </h6>
                            </div>
                            <div className="ms-sm-3">
                                <Badge bg={statusColor} className="super-badge">
                                    {statusLabel}
                                </Badge>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Card.Link className="fw-bold text-dark" onClick={handleShowModal}>
                            <span className="fw-normal text-gray d-block">
                                <small>{note_title.toLowerCase()}</small>
                            </span>
                        </Card.Link>
                    </div>
                </Col>
                { permissions.includes('updateStatusNote') &&
                    <Col xs={10} sm={1} lg={1} xl={1} className="d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-right justify-content-center px-md-0">
                        <Dropdown className="d-flex justify-content-end">
                            <Dropdown.Toggle as={Button} variant="link" className="text-dark dropdown-toggle-split m-0 p-0">
                                <DotsHorizontalIcon className="icon icon-xs icon-dark" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
                                {status === 'registered' ? (
                                    <>
                                    <Dropdown.Item as={Button} variant="link" className={`d-flex align-items-center text-warning`} onClick={() => toggleStatus("Archived")}>
                                            <CheckCircleIcon className="dropdown-icon me-2" /> Archive
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Button} variant="link" className={`d-flex align-items-center text-success`} onClick={() => toggleStatus("Done")}>
                                            <CheckCircleIcon className="dropdown-icon me-2" /> Mark as Done
                                    </Dropdown.Item>
                                    </>
                                ) : status === 'done' ? (
                                    <>
                                        <Dropdown.Item as={Button} variant="link" className={`d-flex align-items-center text-primary`} onClick={() => toggleStatus("Registered")}>
                                            <CheckCircleIcon className="dropdown-icon me-2" /> Restore
                                        </Dropdown.Item>
                                    </>
                                ) : status === 'archived' ? (
                                    <Dropdown.Item as={Button} variant="link" className={`d-flex align-items-center text-primary`} onClick={() => toggleStatus("Registered")}>
                                        <CheckCircleIcon className="dropdown-icon me-2" /> Restore
                                    </Dropdown.Item>
                                ) : null}

                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                }
            </Card.Body>
        </Card>
         <ModalDescription
         show={showModal}
         handleClose={handleCloseModal}
         name={note_title}
         description={description}
         createdAt={created_at}
         user={user_name}
     />
 </>
    );
};

export const AttachmentCardWidget = (props) => {
    const [showModal, setShowModal] = useState(false);

    const formCheckId = `attachment-${props}`;
    const statusEntity = {
        archived: { color: "danger", label: "Archived" },
        available: { color: "secondary", label: "Available" },
    };

    const today = new Date();
    const expirationDate = new Date(props.expire_at);

    const isExpired = expirationDate < today;

    const toggleStatus = (newStatus) => {
        props.toggleStatus && props.toggleStatus(props.id, newStatus);
    };

    const downloadAttachment = async (e) => {
        e.preventDefault();
        try {
            const response = await api.get(`${BASE_URL}/attachment/download/${props.id}`, {
                responseType: 'blob',
            });

            const contentDisposition = response.headers['content-disposition'];
            const fileName = contentDisposition
                ? contentDisposition.split('filename=')[1].replace(/"/g, '')
                : 'downloaded_file';
            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const selectAttachment = (e) => {
        props.selectAttachment && props.selectAttachment(props.id, e.target.checked);
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    let permissions = getPermissions();

    return (
        <>
            <Card border="bottom" className="hover-state rounded-0 rounded-top py-3">
                <Card.Body className="d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0">
                    { permissions.includes('updateStatusAttachment') &&
                        <Col xs={1} className="text-left text-sm-center mb-2 mb-sm-0">
                            <Form.Check type="checkbox" className="form-check check-lg inbox-check me-sm-2">
                                <Form.Check.Input id={formCheckId} checked={props.isSelected} onChange={selectAttachment} />
                            </Form.Check>
                        </Col>
                    }
                    <Col xs={11} lg={10} className="px-0 mb-4 mb-md-0">
                        <div className="mb-2">
                            <div className="d-block d-sm-flex align-items-center">
                                <div>
                                    <h6 className="fw-normal text-gray mb-3 mb-sm-0">
                                        <ClockIcon className="icon icon-xxs text-gray-500 me-2" /> {props.formatted_created_at} - {props.user.name}
                                    </h6>
                                </div>
                                <div className="ms-sm-3 d-flex align-items-center">
                                    <Badge className="super-badge">{props.attachment_type.name}</Badge>
                                    {props.status === "archived" && (
                                        <Badge bg={statusEntity.archived.color} className={`ms-2 text-${statusEntity.archived}`}>
                                            {statusEntity.archived.label}
                                        </Badge>
                                    )}
                                    {props.status === "available" && (
                                        <Badge bg={statusEntity.available.color} className={`ms-2 text-${statusEntity.available}`}>
                                            {statusEntity.available.label}
                                        </Badge>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <Card.Link className="fw-bold text-dark" onClick={handleShowModal}>
                                { props.attachment_type_id === 3 &&
                                    <span className="fw-normal text-gray d-block">
                                        { isExpired &&
                                            <small>Expired at: {props.expire_at.toLowerCase()}</small>
                                        }
                                        { !isExpired &&
                                            <small>Expire at: {props.expire_at.toLowerCase()}</small>
                                        }
                                        
                                    </span>
                                }
                                <span className="fw-normal text-gray d-block">
                                    <small>{props.attachment_title.toLowerCase()}</small>
                                </span>
                            </Card.Link>
                        </div>
                    </Col>
                    { ((permissions.includes('updateStatusContract') && props.attachment_type_id === 3)
                        || (permissions.includes('updateStatusAttachment') && props.attachment_type_id !== 3)
                        || (permissions.includes('downloadContract') && props.attachment_type_id === 3)
                        || (permissions.includes('downloadAttachment') && props.attachment_type_id !== 3)) &&
                        <Col xs={10} sm={1} lg={1} xl={1} className="d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-right justify-content-center px-md-0">
                            <Dropdown className="d-flex justify-content-end">
                                <Dropdown.Toggle as={Button} variant="link" className="text-dark dropdown-toggle-split m-0 p-0">
                                    <DotsHorizontalIcon className="icon icon-xs icon-dark" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
                                    {props.status !== "archived" && ((permissions.includes('downloadContract') && props.attachment_type_id === 3) || (permissions.includes('downloadAttachment') && props.attachment_type_id !== 3)) && (
                                        <Dropdown.Item as={Button} variant="link" className={`d-flex align-items-center`} onClick={downloadAttachment}>
                                            <DownloadIcon className="dropdown-icon me-2" /> Download Attachment
                                        </Dropdown.Item>
                                    )}
                                    {props.status === "archived" && ((permissions.includes('updateStatusContract') && props.attachment_type_id === 3) || (permissions.includes('updateStatusAttachment') && props.attachment_type_id !== 3)) && (
                                        <Dropdown.Item
                                            as={Button}
                                            variant="link"
                                            className={`d-flex align-items-center text-${statusEntity.available.color}`}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                toggleStatus("Available");
                                            }}
                                        >
                                            <CheckCircleIcon className="dropdown-icon me-2" /> Restore
                                        </Dropdown.Item>
                                    )}
                                    {(props.status === "available" || props.status === null) && ((permissions.includes('updateStatusContract') && props.attachment_type_id === 3) || (permissions.includes('updateStatusAttachment') && props.attachment_type_id !== 3)) && (
                                        <Dropdown.Item
                                            as={Button}
                                            variant="link"
                                            className={`d-flex align-items-center text-${statusEntity.archived.color}`}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                toggleStatus("Archived");
                                            }}
                                        >
                                            <CheckCircleIcon className="dropdown-icon me-2" /> Archive
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    }
                </Card.Body>
            </Card>

            <ModalDescriptionAttachments
                show={showModal}
                handleClose={handleCloseModal}
                data={props}
            />
        </>
    );
};

export const ProspectsMetricsOverViewWidget = (props) => {
    const { category } = props;

    const [metrics, setMetrics] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await api.get(`${BASE_URL}/dashboard/prospects/metrics`);
                if (response.status === 403) {
                    setLoading(false);
                    return;
                }
                const { countProspects, countByStatus } = response.data.data;
                const chartData = {
                    labels: Object.keys(countByStatus).map(label => label.replace(/_/g, ' ')),
                    data: Object.values(countByStatus),
                };

                setMetrics({ countProspects, countByStatus, chartData });
            } catch (error) {
                console.error('Error fetching  prospects metrics:', error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };

        fetchMetrics();
    }, []);

    if (loading) {
        return <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
    }

    if (!metrics) {
        return null;
    }

    return (
        <Card border="0" className="shadow">
            <Card.Body>
                <Row className="d-block d-xxl-flex align-items-center">
                    <Col xs={12} xxl={6} className="px-xxl-0 mb-3 mb-xxl-0">
                        <RevenueChart labels={metrics.chartData.labels} data={metrics.chartData.data} />
                    </Col>
                    <Col xs={12} xxl={6} className="ps-xxl-4 pe-xxl-0">
                        <h2 className="fs-5 fw-normal mb-1">
                            {category}
                        </h2>
                        <h3 className="fw-extrabold mb-1">
                            {metrics.countProspects}
                        </h3>
                        <div className="mt-3">
                            <div className="mt-2">
                                <small className="d-flex align-items-center">
                                    <CollectionIcon className="icon icon-xxs text-gray-400 me-1" />
                                    <span className="text-muted">Count By Status:</span>
                                </small>
                                <ul>
                                    {Object.entries(metrics.countByStatus).map(([status, count]) => (
                                        <small key={status}>
                                            <li className="text-muted" style={{ fontSize: '0.875rem' }}>
                                                <strong>{(status || 'Unspecified').replace(/_/g, ' ')}</strong>: {count}
                                            </li>
                                        </small>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export const StoresMetricsOverViewWidget = (props) => {
    const { category } = props;
    const [metrics, setMetrics] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await api.get(`${BASE_URL}/dashboard/stores/metrics`);
                if (response.status === 403) {
                    setLoading(false);
                    return;
                }
                const { data } = response.data;
                setMetrics({ countStores: data });
            } catch (error) {
                console.error('Error fetching stores metrics:', error);
                setLoading(false);
            }finally{
                setLoading(false);
            }
        };

        fetchMetrics();
    }, []);

    if (loading) {
        return <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
    }
    if (!metrics) {
        return  null;
    }
    return (
        <Card border="0" className="shadow">
            <Card.Body>
                <Row className="d-block d-xxl-flex align-items-center">
                    <Col xs={12} xxl={6} className="px-xxl-0 mb-3 mb-xxl-0">
                        <OfficeBuildingIcon className="icon icon-xxl text-primary" />
                    </Col>
                    <Col xs={12} xxl={6} className="ps-xxl-4 pe-xxl-0">
                        <h2 className="fs-5 fw-normal mb-1">
                            {category}
                        </h2>
                        <h3 className="fw-extrabold mb-1">
                            {metrics.countStores}
                        </h3>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export const BranchesMetricsOverViewWidget = (props) => {
    const { category } = props;
    const [metrics, setMetrics] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await api.get(`${BASE_URL}/dashboard/branches/metrics`);
                if (response.status === 403) {
                    setLoading(false);
                    return;
                }
                const { data } = response.data;
                setMetrics({ countStores: data });
            } catch (error) {
                console.error('Error fetching stores metrics:', error);
                setLoading(false);
            }finally{
                setLoading(false);
            }
        };

        fetchMetrics();
    }, []);

    if (loading) {
        return <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
    }

    if (!metrics) {
        return null;
    }

    return (
        <Card border="0" className="shadow mt-1">
            <Card.Body>
                <Row className="d-block d-xxl-flex align-items-center">
                    <Col xs={12} xxl={6} className="px-xxl-0 mb-3 mb-xxl-0">
                        <LocationMarkerIcon className="icon icon-xxl text-primary" />
                    </Col>
                    <Col xs={12} xxl={6} className="ps-xxl-4 pe-xxl-0">
                        <h2 className="fs-5 fw-normal mb-1">
                            {category}
                        </h2>
                        <h3 className="fw-extrabold mb-1">
                            {metrics.countStores}
                        </h3>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export const TeamMembersWidget = () => {
    const TeamMember = (props) => {
        const { user_name, sessions_opened } = props;

        return (
            <ListGroup.Item className="px-0">
                <Row className="align-items-center">
                    <Col xs="auto" className="ms--2">
                        <h4 className="h6 mb-0">
                            <Card.Link>
                                {user_name} [sessions opened: {sessions_opened}]
                            </Card.Link>
                        </h4>
                        <div className="d-flex align-items-center">
                            <div className={`bg-success dot rounded-circle me-1`} />
                            <small>Online</small>
                        </div>
                    </Col>
                </Row>
            </ListGroup.Item>
        );
    };

    const [metrics, setMetrics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const itemsPerPage = 10;

    const fetchMetrics = async (page = 1) => {
        try {
            setLoading(true);
            const response = await api.get(`${BASE_URL}/dashboard/auth/metrics`, {
                params: { page, per_page: itemsPerPage }
            });

            if (response.status === 403) {
                setLoading(false);
                return;
            }

            const { data, pagination } = response.data;
            setMetrics({ teamMembers: data });
            setTotalItems(pagination.total_items);
        } catch (error) {
            console.error('Error fetching team metrics:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMetrics(currentPage);
    }, [currentPage]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    if (!metrics) {
        return null;
    }

    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <Card border="0" className="shadow">
            <Card.Header className="border-bottom d-flex align-items-center justify-content-between">
                <h2 className="fs-5 fw-bold mb-0">Online Team members</h2>
            </Card.Header>
            <Card.Body>
                <ListGroup className="list-group-flush list my--3">
                    {metrics.teamMembers.map((tm) => (
                        <TeamMember key={`team-member-${tm.user_id}`} {...tm} />
                    ))}
                </ListGroup>
            </Card.Body>
            <Card.Footer style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
                     {/* Paginação */}
             {totalItems > itemsPerPage && (
                    <div className="d-flex justify-content-center align-items-center mt-3">
                        <button
                            className="btn btn-link"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === totalPages}
                            style={{ backgroundColor: 'primary', border: 'round' }}
                        >
                            Next
                        </button>
                        <span className="mx-2">
                            <span className="badge bg-primary text-white rounded-circle py-2 px-3">
                                {currentPage}
                            </span>
                        </span>
                        <button
                            className="btn btn-link"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            style={{ backgroundColor: 'primary', border: 'round' }}

                        >
                           Previous
                        </button>
                    </div>
                )}
            </Card.Footer>
        </Card>
    );
};

export const TimelineWidget = (props) => {
    const { title,userName } = props;
    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const TimelineItem = (props) => {
        const { note_title, description, created_at, status, notable_type, icon: ItemIcon, iconBg } = props;

        const [showModal, setShowModal] = useState(false);
        const handleShowModal = () => setShowModal(true);
        const handleCloseModal = () => setShowModal(false);

        const statusEntity = {
            archived: { color: "warning", label: "Archived" },
            registered: { color: "primary", label: "Registered" }
        };

        const currentStatus = statusEntity[status] || statusEntity.registered;
        const statusColor = currentStatus.color;
        const statusLabel = currentStatus.label;

        let IconComponent;

        if (status === 'registered') {
            IconComponent = CheckCircleIcon;
        } else {
            IconComponent = null;
        }

        return (
            <>
                <ListGroup.Item className="border-o">
                    <Row className="ps-lg-1">
                        <Col xs="auto">
                            <div className={`icon-shape icon-xs icon-shape-${iconBg} rounded`}>
                                {IconComponent && <IconComponent className="h-5 w-5 text-gray-600" />}
                            </div>
                        </Col>
                        <Col className="ms-n2 mb-3">
                            <Row className="align-items-center">
                                <Col xs="auto">
                                    <h3
                                        className="fs-6 fw-bold mb-1 clickable"
                                        onClick={handleShowModal}
                                        style={{
                                            cursor: 'pointer',
                                            transition: 'color 0.3s ease',
                                        }}
                                        onMouseEnter={(e) => e.target.style.color = '#007bff'}
                                        onMouseLeave={(e) => e.target.style.color = ''}
                                    >
                                        {note_title}
                                    </h3>
                                </Col>
                                <Col xs="auto">
                                    <Badge bg={statusColor} className="super-badge">
                                        {statusLabel}
                                    </Badge>
                                </Col>
                            </Row>
                            <div className="ms-sm-3">
                                <Card.Text className="mb-1">
                                    {notable_type}
                                </Card.Text>
                            </div>
                            <div className="d-flex align-items-center">
                                <ClockIcon className="icon icon-xxs text-gray-400 me-1" />
                                <small>{formatDate(created_at)}</small>
                            </div>
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ModalDescription
                    show={showModal}
                    handleClose={handleCloseModal}
                    name={note_title}
                    description={description}
                    createdAt={formatDate(created_at)}
                    user={userName}
                />
            </>
        );
    };

    const [metrics, setMetrics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const itemsPerPage = 10;

    const fetchMetrics = async (page = 1) => {
        try {
            setLoading(true);
            const response = await api.get(`${BASE_URL}/dashboard/notes/metrics`, {
                params: { page, per_page: itemsPerPage }
            });

            if (response.status === 403) {
                setLoading(false);
                return;
            }

            const { data, pagination } = response.data;
            setMetrics({ productNotifications: data });
            setTotalItems(pagination.total_items);
        } catch (error) {
            console.error('Error fetching notes metrics:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMetrics(currentPage);
    }, [currentPage]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }
    if (!metrics) {
        return null;
    }

    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    return (
        <Card border="0" className="notification-card shadow" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Card.Header className="d-flex align-items-center">
                <h2 className="fs-5 fw-bold mb-0">
                    {title}
                </h2>
            </Card.Header>
            <Card.Body style={{ flex: '1', overflowY: 'auto' }}>
                {totalItems === 0 ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                        <p className="text-center text-muted">
                            No record of unfinished notes.
                        </p>
                    </div>
                ) : (
                    <ListGroup className="list-group-flush list-group-timeline">
                        {metrics.productNotifications.map((notif) => (
                            <TimelineItem key={`notif-${notif.id}`} {...notif} />
                        ))}
                    </ListGroup>
                )}
            </Card.Body>
            <Card.Footer style={{ padding: '1rem', backgroundColor: '#f8f9fa' }}>
                {totalItems > itemsPerPage && (
                    <div className="d-flex justify-content-center align-items-center mt-3">
                        <button
                            className="btn btn-link"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            style={{ backgroundColor: 'primary', border: 'round' }}
                        >
                            Next
                        </button>
                        <span className="mx-2">
                            <span className="badge bg-primary text-white rounded-circle py-2 px-3">
                                {currentPage}
                            </span>
                        </span>
                        <button
                            className="btn btn-link"
                            onClick={() => handlePageChange(currentPage - 1)} // Página anterior
                            disabled={currentPage === 1}
                            style={{ backgroundColor: 'primary', border: 'round' }}
                        >
                            Previous
                        </button>
                    </div>
                )}
            </Card.Footer>
        </Card>
    );
};
