import React, { useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { RoutesPath } from "routes";
import { requiredPermissions } from 'routes';
import { getPermissions, getToken, removeToken } from "./../service/authService"
// pages
import Presentation from 'pages/Presentation';
import Prospects from './clients/prospects/Prospects';
import { NewProspectForm } from './clients/prospects/NewProspectForm';
import { EditProspectForm } from './clients/prospects/EditProspectForm';
import { ViewProspectForm } from './clients/prospects/ViewProspectForm';
import User from './users/Users';
import Stores from './clients/stores/Stores';
import { NewStoreForm } from './clients/stores/NewStoreForm';
import StoresBranches from './clients/stores/branches/StoresBranches';
import { NewStoreBranchForm } from './clients/stores/branches/NewStoreBranchForm';
import { EditStoreBranchForm } from './clients/stores/branches/EditStoreBranchForm';
import { ViewStoreBranchForm } from './clients/stores/branches/ViewStoreBranchForm';
import {NewUserForm} from './users/NewUserForm';
import {UpdateUserForm} from './users/UpdateUserForm'
// components
import Sidebar from 'components/Sidebar';
import Topbar from 'components/Topbar';
import Signin from './Signin';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Notes from './Notes';
import Attachments from './Attachments';
import Unauthorized from './Unauthorized';
import ViewProspectStatusHistory from './clients/prospects/ViewProspectStatusHistory';
import Contracts from './Contracts';
import api from '../service/api';
import { BASE_URL } from '../helpers/config';

const RouteWithSidebar = ({ element: Component, ...rest }) => {

  const resize = () => {
    var resize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    setTimeout(function () {
      clearInterval(resize);
    }, 301);
  }

  const localStorageIsContracted = () => {
    return localStorage.getItem('sidebarContracted') === 'false' ? false : true;
  }

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true;
  }

  const [contracted, setContracted] = useState(localStorageIsContracted());
  const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());
  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible());

  const toggleMouseOver = () => {
    if (contracted) {
      setContractSidebar(!contractSidebar);
    }
    resize();
  };

  const toggleContracted = () => {
    setContracted(!contracted);
    setContractSidebar(!contracted);
    localStorage.setItem('sidebarContracted', !contracted);
    resize();
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <>

      <Sidebar
        contracted={contractSidebar}
        onMouseEnter={toggleMouseOver}
        onMouseLeave={toggleMouseOver}
      />

      <main className="content">
        <Topbar toggleContracted={toggleContracted} />
        <Component />
      </main>
    </>
  );
};

function RequireAuth({ children }) {
    let location = useLocation();
    const navigate = useNavigate();
    let token = getToken();
    let permissions = getPermissions();
    const currentPath = location.pathname;

    api.get(`${BASE_URL}/check-auth`).catch(() => {
      removeToken()
      navigate('/login')
    });

    if (!token) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    const requiresPasswordChange = JSON.parse(localStorage.getItem('requiresPasswordChange'));

    if (requiresPasswordChange) {
      let $message="Please change your password on first login.";
      return <Navigate to={`${RoutesPath.ResetPassword.path}?identifier=user&token=${token}&requiresPasswordChange=${requiresPasswordChange}&message=${encodeURIComponent($message)}`} replace/>;
    }

    const requiredPermission = requiredPermissions.find(
      (permission) => permission.pathRegex.test(currentPath)
    )?.permission;

    if (requiredPermission && !permissions.includes(requiredPermission)) {
      return <Navigate to={RoutesPath.Unauthorized.path}/>
    }

    return children;
}

export default function App() {
  return (
      <Routes>
        <Route
          path={RoutesPath.ForgotPassword.path}
          element={<ForgotPassword />}
        />
        <Route
          path={RoutesPath.ResetPassword.path}
          element={<ResetPassword />}
        />
        <Route
          path={RoutesPath.Presentation.path}
          element={
            <RequireAuth>
               <RouteWithSidebar element={Presentation} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.Signin.path}
          element={<Signin />}
        />
        <Route
          path={RoutesPath.Prospects.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={Prospects} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.NewProspectForm.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={NewProspectForm} />
            </RequireAuth>
          }

        />
        <Route
          path={RoutesPath.EditProspectForm.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={EditProspectForm} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.ViewProspectForm.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={ViewProspectForm} />
            </RequireAuth>
          }
        />
         <Route
          path={RoutesPath.ViewProspectStatusHistory.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={ViewProspectStatusHistory} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.Stores.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={Stores} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.NewStoreForm.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={NewStoreForm} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.EditStoreBranchForm.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={EditStoreBranchForm} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.ViewStoreBranchForm.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={ViewStoreBranchForm} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.StoresBranches.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={StoresBranches} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.NewStoreBranchForm.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={NewStoreBranchForm} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.Notes.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={Notes} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.Attachments.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={Attachments} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.Contracts.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={Contracts} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.Users.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={User} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.NewUserForm.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={NewUserForm} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.EditUserForm.path}
          element={
            <RequireAuth>
              <RouteWithSidebar element={UpdateUserForm} />
            </RequireAuth>
          }
        />
        <Route
          path={RoutesPath.Unauthorized.path}
          element={<Unauthorized />}
        />
        <Route path="*" element={<Navigate to={RoutesPath.NotFound.path} />} />
      </Routes>
  );
}
