import React, { useState, useRef } from "react";
import { LockClosedIcon, UserIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, Container, InputGroup, Card, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../helpers/config';
import { RoutesPath } from "routes";
import { savePermissions, saveToken, saveUserName } from '../service/authService';
import ReCAPTCHA from "react-google-recaptcha";
export default () => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const recaptchaRef = useRef();
  const navigate = useNavigate();
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  const phoneRegex = /^[0-9]{10,15}$/;

  const validateForm = () => {
    if (!identifier) {
      setMessage("Please enter your email or phone number.");
      setMessageType("warning");
      return false;
    }
    if (!password) {
      setMessage("Please enter your password.");
      setMessageType("warning");
      return false;
    }

    if (!emailRegex.test(identifier) && !phoneRegex.test(identifier)) {
      setMessage("Please enter a valid email or phone number(only digits).");
      setMessageType("danger");
      return false;
    }

    if (phoneRegex.test(identifier) && /[^0-9]/.test(identifier)) {
      setMessage("For phone numbers, please enter only numbers without any special characters (e.g., no dashes or parentheses).");
      setMessageType("danger");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      setMessage("Please verify that you are not a robot.");
      setMessageType("warning");
      return;
    }

    if (!validateForm()) {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setRecaptchaValue(null);
      return;
    }

    setLoading(true);
    setMessage("");

    const request = { identifier, password };

    try {
      const resp = await axios.post(`${BASE_URL}/login`, request);
      const token = resp.data.token;
      const permissions = resp.data.permissions;
      const requiresPasswordChange = resp.data.requires_password_change;
      const message = resp.data.message;
      let userName = '';
      if (Array.isArray(resp.data.userName)) {
        userName = resp.data.userName.join(' ');
        saveUserName(userName);
    }
      saveToken(token);
      savePermissions(permissions);

      if (requiresPasswordChange) {
        localStorage.setItem('requiresPasswordChange', JSON.stringify(requiresPasswordChange));
        navigate(`${RoutesPath.ResetPassword.path}?identifier=${identifier}&token=${token}&requiresPasswordChange=${requiresPasswordChange}&message=${encodeURIComponent(message)}`);
      } else {
        localStorage.setItem('requiresPasswordChange', JSON.stringify(false));
        setWelcomeMessage(`Welcome back, ${userName}!`);
        localStorage.setItem("welcomeMessage", `Welcome back, ${userName}!`);
        navigate('/');
      }
    } catch (error) {
      console.log('Error while logging in:', error.response?.data?.error);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setRecaptchaValue(null);

      if (error.response && error.response.data && error.response.data.error === "Inactive user") {
        setMessage("Inactive User");
      } else {
        setMessage("Invalid email or password. Please try again.");
      }
      setMessageType("danger");
    } finally {
      setLoading(false);
    }
  };

  const handleCapsLock = (e) => {
    if (e.getModifierState("CapsLock")) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <img
                    src={`${process.env.PUBLIC_URL}/logo/Logo-MQM-Official-Mobile-08-2021.svg`}
                    alt="Logo"
                    className="img-fluid mb-4"
                    style={{ maxWidth: '90px' }}
                  />
                  <h3 className="mb-0">Sign in</h3>
                </div>

                {message && (
                    <Alert
                    variant={messageType}
                    className="mb-3"
                    style={{
                      padding: '5px 10px',
                      margin: '0 auto',
                      maxWidth: '400px',
                      fontSize: '14px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    {message}
                  </Alert>

                )}


                {welcomeMessage && (
                  <Alert variant="success" className="mb-3">
                    {welcomeMessage}
                  </Alert>
                )}

                <Form onSubmit={handleSubmit} className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Email | Phone Number</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <UserIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        value={identifier}
                        onChange={(e) => setIdentifier(e.target.value)}
                        placeholder="Your Credential"
                        onKeyDown={handleCapsLock}
                        onKeyUp={handleCapsLock}
                        maxLength={100}
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleCapsLock}
                        onKeyUp={handleCapsLock}
                        placeholder="Password"
                        maxLength={100}
                      />
                    </InputGroup>
                    {isCapsLockOn && (
                        <div className="mb-3 text-primary">
                            <span style={{ fontSize: '12px' }}><strong>Warning: Caps Lock is ON!</strong></span>
                        </div>
                    )}
                  </Form.Group>
                  <div className="d-flex justify-content-between align-items-top mb-4">
                    <Card.Link
                      to={RoutesPath.ForgotPassword.path}
                      href={'/#' + RoutesPath.ForgotPassword.path}
                      className="small text-end text-decoration-none text-primary hover:text-light"
                    >
                      Lost password?
                    </Card.Link>
                  </div>
                    <div className="mb-3">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LcM63gqAAAAAH1CdFxM2jlmFOZqqKvu2B6yM2Rj"
                        onChange={(value) => setRecaptchaValue(value)}
                        size="normal"
                      /> 
                    </div>
                  <div className="d-grid">
                    <Button variant="gray-800" type="submit" disabled={loading || !recaptchaValue}>
                      {loading ? 'Signing in...' : 'Sign in'}
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
