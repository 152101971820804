import React, { useState, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Datetime from 'react-datetime';
import { CalendarIcon } from "@heroicons/react/solid";
import moment from 'moment-timezone';

const ClientSinceInput = ({ value, onChange, mode }) => {
  const [selectedDate, setSelectedDate] = useState(value || '');

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  // Função que garante que a data não seja no futuro
  const handleDateChange = (date) => {
    const today = moment();
    const selectedMoment = moment(date);

    // Se a data for maior que hoje, ajuste para a data atual
    if (selectedMoment.isAfter(today, 'day')) {
      const formattedDate = today.format('YYYY-MM-DD');
      setSelectedDate(formattedDate);
      onChange(formattedDate);
    } else {
      const formattedDate = selectedMoment.format('YYYY-MM-DD');
      setSelectedDate(formattedDate);
      onChange(formattedDate);
    }
  };

  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  // Data de hoje para comparação
  const today = moment();

  return (
    <Form.Group id="clientSince" name="since">
      <RequiredLabel label="Client Since" />
      {mode === 'view' ? (
        <Form.Control
          type="text"
          value={selectedDate}
          readOnly
          disabled
        />
      ) : (
        <Datetime
          timeFormat={false}
          onChange={handleDateChange}
          // Desabilita a seleção de datas no futuro
          maxDate={today}  // Impede seleção de datas futuras
          // Aqui, não estamos permitindo que datas futuras sejam selecionadas
          renderInput={(props, openCalendar) => (
            <InputGroup>
              <InputGroup.Text>
                <CalendarIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                {...props}
                required
                name="clientSince"
                type="text"
                value={value ? moment(value).format("YYYY/MM/DD") : ""}
                placeholder="yyyy/mm/dd"
                onFocus={openCalendar}
                onChange={() => {}}
              />
            </InputGroup>
          )}
        />
      )}
    </Form.Group>
  );
};

export default ClientSinceInput;
