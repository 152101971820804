import React from "react";
import { Row } from "react-bootstrap";
import {GeneralUserInfoForm } from "../../components/Forms";

  export  const NewUserForm = () => {
    return (
      <>
        <Row>
          <GeneralUserInfoForm />
        </Row>
      </>
    );
  };
