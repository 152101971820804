import React, { useState, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import InputMask from 'react-input-mask';

const PhoneNumberInput = ({ formData, setFormData, mode }) => {
    const [error, setError] = useState('');

    const validatePhone = (phone) => {
        const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
        const cleanedPhone = phone.replace(/\D/g, '');

        if (cleanedPhone.length === 10) {
            setError('');
        } else if (phone && !phoneRegex.test(phone)) {
            setError('Phone number must be in the format (999) 999-9999');
        } else {
            setError('');
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        const cleanedValue = value.replace(/\D/g, '');

        setFormData({ ...formData, [name]: cleanedValue });
        validatePhone(value);
    };

    const RequiredLabel = ({ label }) => (
        <Form.Label>
            {label}
            <span className="text-danger"> * </span>
        </Form.Label>
    );

    useEffect(() => {
        validatePhone(formData.phone);
    }, [formData.phone]);

    return (
        <Form.Group id="phone">
            <RequiredLabel label="Phone Number" />
            <InputGroup>
                {mode === 'view' ? (
                    <Form.Control
                        type="text"
                        value={formData.phone ? `(${formData.phone.substring(0, 3)}) ${formData.phone.substring(3, 6)}-${formData.phone.substring(6)}` : ''}
                        readOnly
                    />
                ) : (
                    <InputMask
                        mask="(999) 999-9999"
                        value={formData.phone ? `(${formData.phone.substring(0, 3)}) ${formData.phone.substring(3, 6)}-${formData.phone.substring(6)}` : ""}
                        onChange={handleChange}
                    >
                        {(inputProps) => (
                            <Form.Control
                                {...inputProps}
                                required
                                type="text"
                                name="phone"
                            />
                        )}
                    </InputMask>
                )}
            </InputGroup>
            {error && <Form.Text className="text-danger">{error}</Form.Text>}
        </Form.Group>
    );
};

export default PhoneNumberInput;
