
import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { Col, Row, Button, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';


export default () => {
  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <h5 className="mt-5">
                  Unauthorized, you do not have permission to access this route.
                </h5>
                <Button as={Link} variant="gray-800" className="d-inline-flex align-items-center justify-content-center mb-4 mt-4" to={`/`}>
                  <ChevronLeftIcon className="icon icon-xs me-21" />
                  Go back home
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
