import React from 'react';
import { Form, Dropdown } from 'react-bootstrap';

const SelectAttachmentType = ({ availableTypes, addNewAttachment }) => {
    return (
      availableTypes.length > 0 && (
        <Form.Group className="mb-3" id="attachmentTypeSelect">
          <Form.Label>Select an Attachment Type</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              const selectedType = availableTypes.find(type => type.name === e.target.value);
              if (selectedType) {
                addNewAttachment(selectedType.name, selectedType.id);
              }
            }}
            defaultValue=""
          >
            <option value="">Select an attachment type</option>
            {availableTypes.map((value) => (
              <option key={value.id} value={value.name}>
                {value.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )
    );
  };


export default SelectAttachmentType;
