import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import api from '../../../service/api';
import { BASE_URL } from '../../../helpers/config';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

const SelectUserType = ({ userTypeId, selectedType = null, onTypeChange, nameLabel, mode }) => {
  const [usersType, setUsersType] = useState([]);

  useEffect(() => {
    const fetchUserTypes = async () => {
      try {
        const response = await api.get(`${BASE_URL}/users/userType/${userTypeId}`);
        setUsersType(Object.values(response.data.data));
      } catch (error) {
        console.error(error);
        await SwalWithBootstrapButtons.fire(
          `Error loading users type ${nameLabel}`,
          error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue loading the user types. Please try again later.",
          "error"
        );
      }
    };

  fetchUserTypes();
  }, []);

  function findUserNameById(userId) {
    const user = usersType.find(user => user.id === userId);
    return user ? user.name : null;
  }

  return (
    <Form.Group id="salesRepresentative">
      <Form.Label>{nameLabel}</Form.Label>

      {mode === 'view' ? (
        <Form.Control
          type="text"
          value={findUserNameById(selectedType)}
          readOnly
          disabled
      />
      ): (
        <InputGroup>
          <Form.Select
            name="salesRepresentative"
            value={selectedType}
            onChange={(e) => onTypeChange(e.target.value)}
          >
          <option value="">Select a user</option>
            {usersType.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
      )}
    </Form.Group>
  );
};

export default SelectUserType;
