import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { EditProspectInfoForm } from "../../../components/Forms";
import { useParams } from 'react-router-dom';
import api from '../../../service/api';
import { BASE_URL } from '../../../helpers/config';

export const EditProspectForm = () => {
  const { id } = useParams();
  const [prospect, setProspect] = useState(null);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`${BASE_URL}/prospect/${id}`);
        setProspect(response.data.data);
        setLoading(false);

      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
  
  }

  return (
    <Row>
      {prospect ? <EditProspectInfoForm prospect={prospect} /> : null}
    </Row>
  );
};
