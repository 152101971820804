import axios from 'axios';
import { getToken, removeToken } from './authService';
import { useNavigate } from 'react-router-dom';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      //config.headers['Content-Type'] = "application/json";
    } else {
      const navigate = useNavigate();
      navigate('/login'); 
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => response,
  error => {
    const navigate = useNavigate();

    if (error.response && error.response.status === 403) {
      navigate('/#/unauthorized');
    } else if (error.response && error.response.status === 401) {
      removeToken();
      navigate('/#/login');
    } else {
      console.error('Unexpected error:', error);
    }

    return Promise.reject(error);
  }
);

export default api;
