import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';

const StoreSizeInput = ({ value, onChange, mode }) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label>Store Size (Sq.ft)</Form.Label>
      <InputGroup>
        {mode === 'view' ? (
        <NumericFormat
            value={value}
            className="form-control"
            decimalScale={0}
            fixedDecimalScale={true}
            isNumericString={true}
            allowNegative={false}
            thousandSeparator={true}
            suffix=" Sq.ft"
            readOnly
          />
        ) : (
          <NumericFormat
            value={value}
            onValueChange={({ value }) => onChange(value)}
            placeholder="Enter size in square feet"
            className="form-control"
            decimalScale={0}
            fixedDecimalScale={true}
            isNumericString={true}
            allowNegative={false}
            thousandSeparator={true}
            suffix=" Sq.ft"
            maxLength={100}
          />
        )}
      </InputGroup>
    </Form.Group>
  );
};

export default StoreSizeInput;
