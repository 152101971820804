import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import api from '../../../service/api';
import { BASE_URL } from '../../../helpers/config';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

const SelectFloorType = ({ selectedOptions, onSelectChange, animatedComponents, mode }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchFloorTypes = async () => {
            try {
                const response = await api.get(`${BASE_URL}/floorType`);
                const data = response.data.map(floor => ({
                    label: floor.floor_type_name,
                    value: floor.id
                }));
                setOptions(data);
            } catch (error) {
                console.error('Error fetching floor types:', error);
                await SwalWithBootstrapButtons.fire(
                    "Error loading floor types",
                    error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue loading the floor types. Please try again later.",
                    "error"
                );
            }
        };

        fetchFloorTypes();
    }, []);

    return (
        <Form.Group id="floorType">
            <Form.Label>Floor Type</Form.Label>
            <InputGroup>
                <Select
                    id="floorType"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={options}
                    value={selectedOptions}
                    onChange={mode !== 'view' ? onSelectChange : undefined} // Prevent changes in view mode
                    isDisabled={mode === 'view'} // Disable in view mode
                    placeholder="Select Floor Type"
                    className="w-100"
                />
            </InputGroup>
        </Form.Group>
    );
};

export default SelectFloorType;
