import React from "react";
import { Row } from "react-bootstrap";
import {GeneralStoreBranchInfoForm } from "../../../../components/Forms";

  export  const NewStoreBranchForm = () => {
    return (
      <>
        <Row>
          <GeneralStoreBranchInfoForm />
        </Row>
      </>
    );
  };





