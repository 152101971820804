
import React from "react";
import { MenuAlt1Icon, UserCircleIcon } from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import { Nav, Image, Button, Navbar, Dropdown, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../helpers/config';
import { RoutesPath } from "routes";
import api from './../service/api';
import { removeToken, getUserName, removeUserName, removePermissions } from "service/authService";

export default (props) => {
  const userName = getUserName();
  const toggleContracted = () => props.toggleContracted && props.toggleContracted();

  const navigate = useNavigate();

  const handleLogout = async (navigate) => {

    try {
      await api.post(`${BASE_URL}/logout`);
      removeToken();
      removeUserName();
      removePermissions();

      navigate(RoutesPath.Signin.path);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <Navbar expand variant="dark" className="navbar-top navbar-dashboard ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Button
              size="lg"
              id="sidebar-toggle"
              variant="icon-only"
              className="sidebar-toggle d-none d-lg-inline-block align-items-center justify-content-center me-3"
              onClick={toggleContracted}
            >
              <MenuAlt1Icon className="toggle-icon" />
            </Button>
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item} className="ms-lg-3">
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  {/* <Image src={'/logo/favicon.png'} className="avatar rounded-circle" /> */}
                  <UserCircleIcon className="avatar rounded-circle text-primary"></UserCircleIcon>
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold text-gray-900">{userName}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-end mt-2 py-1">
                {/* <Dropdown.Item className="d-flex align-items-center">
                  <UserCircleIcon className="dropdown-icon text-gray-400 me-2" /> My Profile
                </Dropdown.Item>
                <Dropdown.Divider as="div" className="my-1" /> */}

                <Dropdown.Item
                  className="d-flex align-items-center"
                  onClick={() => handleLogout(navigate)} // Chama a função de logout ao clicar
                >
                  <LogoutIcon className="dropdown-icon text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
