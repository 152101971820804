import React from 'react';
import { Form } from 'react-bootstrap';

const NoteDescriptionInput = ({ note, onChange, isNoteTitleProvided }) => {
  return (
    <Form.Group id="note">
      <Form.Control
        name="note"
        value={note}
        onChange={onChange}
        as="textarea"
        rows="4"
        placeholder="Enter your note..."
        required
        disabled={!isNoteTitleProvided}
        maxLength={150}
      />
      <Form.Text className="text-muted">
        {note.length}/150 characters
      </Form.Text>
    </Form.Group>
  );
};

export default NoteDescriptionInput;
