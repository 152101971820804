import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const InternalCodeInput = ({ value, onChange, mode }) => {
  const [internalCode, setInternalCode] = useState(value);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setInternalCode(value);
  }, [value]);

  const handleChange = (e) => {
    let newValue = e.target.value.toUpperCase();
    if (/^[A-Za-z]*$/.test(newValue) && newValue.length <= 4) {
      setInternalCode(newValue);
      setErrorMessage('');
      onChange && onChange(e);
    } else {
      setErrorMessage('Please enter only alphabetic characters (max 4).');
    }
  };

  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  return (
    <Form.Group id="internalCode">
      <RequiredLabel label="Internal Code" />
        {mode === 'view' ? (
          <Form.Control
            type="text"
            value={value}
            readOnly
          />
          ) : (
          <Form.Control
            type="text"
            name="internalCode"
            placeholder='XXXX'
            value={internalCode}
            onChange={handleChange}
            maxLength={4}
            required
            isInvalid={!!errorMessage}
          />
        )}
      {errorMessage && (
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default InternalCodeInput;
