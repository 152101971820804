import React, { useState } from "react";
import { DotsHorizontalIcon, EyeIcon, OfficeBuildingIcon, PencilAltIcon, PencilIcon, ShieldExclamationIcon, XCircleIcon,PaperClipIcon,ClockIcon,LocationMarkerIcon,RefreshIcon,DocumentTextIcon } from "@heroicons/react/solid";
import { Nav, Card,Button, Table, Dropdown, Pagination, Tooltip, ButtonGroup, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  UserViewModal,
  UserResetPassWordModel,
  StoreEditModal,
  StoreViewModal,
  UpgradeToBranchModel,
  UpgradeToStoreModel,
} from "components/Modals";

import { getPermissions } from "service/authService";

export const UsersTable = (props) => {
  const { users = [], allSelected } = props;
  const totalUsers = users.length;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const totalPages = Math.ceil(totalUsers / pageSize);
  const currentUsers = users.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  const permissions = getPermissions();
  const [bulkOption, setBulkOption] = useState(0);
  const disabledBulkMenu = users.filter((u) => u.isSelected).length === 0;

  const [showResetPassWordModel, setshowResetPassWordModel] = useState(false);
  const [showUserViewModal, setshowUserViewModel] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const selectUser = (id) => {
    props.selectUser && props.selectUser(id);
  };

  const selectAllUsers = () => {
    props.selectAllUsers && props.selectAllUsers();
  };

  const bulkActionChange = (e) => {
    const newOption = e.target.value;
    setBulkOption(newOption);
  };

//   const applyBulkAction = () => {
//     if (bulkOption === "delete_user") deleteUsers();
//   };

//   const deleteUsers = (ids) => {
//     props.deleteUsers && props.deleteUsers(ids);
//   };

//   const deleteUser = (id) => {
//     props.deleteUser && props.deleteUser(id);
//   };

  const toggleResetCardModal = (id = null) => {
    setSelectedUserId(id);
    setshowResetPassWordModel(!showResetPassWordModel);
  };

  const toggleViewCardModal = (id  = null) => {
    setSelectedUserId(id);
    setshowUserViewModel(!showUserViewModal);
  };

  const TableRow = (props) => {
    const { id, status, name, email, created_at, isSelected, phone_number } = props;
    const statusEnum = {
      1: { color: "success", label: "Active" },
      0: { color: "warning", label: "Inactive" },
    };
    const statusColor = statusEnum[status].color, statusLabel = statusEnum[status].label;

    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return phoneNumber;

        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber;
      };

    return (
      <tr className="border-bottom">
        {/* <td>
          <FormCheck type="checkbox" className="dashboard-check">
            <FormCheck.Input
              id={`user-${id}`}
              checked={isSelected}
              onChange={() => selectUser(id)}
            />
            <FormCheck.Label htmlFor={`user-${id}`} />
          </FormCheck>
        </td> */}
        <td>
          <Card.Link className="d-flex align-items-center">
            <div className="d-block">
              <span className="fw-bold">{name}</span>
              <div className="small text-gray">{email}</div>
            </div>
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{created_at}</span>
        </td>
        <td>
          <Card.Link className="d-flex align-items-center">
            <div className="d-block">
              <span className="fw-normal text">{formatPhoneNumber(phone_number)}</span>
            </div>
          </Card.Link>
        </td>
        <td>
          <span className={`fw-normal text-${statusColor}`}>
            {statusLabel}
          </span>
        </td>
          { (permissions.includes('updateUserPassword') || permissions.includes('updateUser') || permissions.includes('getAllAttachments') || permissions.includes('deleteUser')) &&
            <td>
              { (permissions.includes('updateUserPassword') || permissions.includes('updateUser') || permissions.includes('getAllAttachments')) &&
                <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle
                    as={Button}
                    split
                    variant="link"
                    className="text-dark m-0 p-0"
                  >
                    <DotsHorizontalIcon className="icon icon-xs" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
                    { permissions.includes('updateUserPassword') &&
                      <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => toggleResetCardModal(id)}
                      >
                        <ShieldExclamationIcon className="dropdown-icon text-gray-400 me-2" />
                        Update Password
                      </Dropdown.Item>
                    }
                      <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => toggleViewCardModal(id)}
                      >
                        <EyeIcon className="dropdown-icon text-gray-400 me-2" />
                        View Details
                      </Dropdown.Item>
                    { permissions.includes('updateUser') &&
                      <Dropdown.Item
                        className="d-flex align-items-center"
                        as={Link}
                        to={`/users/edit-user/${id}`}
                      >
                        <PencilIcon className="dropdown-icon text-gray-400 me-2" />
                        Edit User
                      </Dropdown.Item>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              }
              {/* { permissions.includes('deleteUser') &&
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip className="m-0">Delete</Tooltip>}
                >
                  <Card.Link className="ms-2" onClick={() => deleteUser(id)}>
                    <XCircleIcon className="icon icon-xs text-danger" />
                  </Card.Link>
                </OverlayTrigger>
              } */}
            </td>
          }
      </tr>
    );
  };
  return (
    <>
      {showResetPassWordModel && (
        <UserResetPassWordModel
          show={showResetPassWordModel}
          onHide={toggleResetCardModal}
          userId={selectedUserId}
          refreshUsers={props.fetchData}
        />
      )}

      {showUserViewModal && (
        <UserViewModal
          show={showUserViewModal}
          onHide={toggleViewCardModal}
          userId={selectedUserId}
        />
      )}

      <Card border="0" className="table-wrapper table-responsive shadow">
        <Card.Body>
          {/* <div className="d-flex mb-3">
            <Form.Select
              className="fmxw-200"
              disabled={disabledBulkMenu}
              value={bulkOption}
              onChange={bulkActionChange}
            >
              <option value="bulk_action">Bulk Action</option>
              <option value="send_email">Send Email</option>
              <option value="change_group">Change Group</option>
              <option value="delete_user">Delete User</option>
            </Form.Select>
            <Button
              variant="secondary"
              size="sm"
              className="ms-3"
              disabled={disabledBulkMenu}
              onClick={applyBulkAction}
            >
              Apply
            </Button>
          </div> */}
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                {/* <th className="border-bottom">
                  <FormCheck type="checkbox" className="dashboard-check">
                    <FormCheck.Input
                      id="userCheckAll"
                      checked={allSelected}
                      onChange={selectAllUsers}
                    />
                    <FormCheck.Label htmlFor="userCheckAll" />
                  </FormCheck>
                </th> */}
                <th className="border-bottom">Name</th>
                <th className="border-bottom">Created At</th>
                <th className="border-bottom">Phone Number</th>
                <th className="border-bottom">Status</th>
                { (permissions.includes('updateUserPassword') || permissions.includes('updateUser') || permissions.includes('getAllAttachments') || permissions.includes('deleteUser')) &&
                  <th className="border-bottom">Action</th>
                }
              </tr>
            </thead>
            <tbody className="border-0">
              {currentUsers.map((u) => (
                <TableRow key={`user-${u.id}`} {...u} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-0">
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                  Previous
                </Pagination.Prev>
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-normal mt-4 mt-lg-0">
              Showing <b>{currentUsers.length}</b> out of <b>{totalUsers}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};

export const ProspectsTable = (props) => {
    const { prospects = [] } = props;
    const totalProspects = prospects.length;
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const totalPages = Math.ceil(totalProspects / pageSize);
    const currentProspects = prospects.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const [showUpgradeToBranchModal, setShowUpgradeToBranchModal] = useState(false);
    const [showUpgradeToStoreModal, setShowUpgradeToStoreModal] = useState(false);
    const [selectedProspectId, setSelectedProspectId] = useState(null);
    const [selectedCompany,setSelectedCompany] = useState(null);
    const [selectedServiceTypeId, setSelectedServiceTypeId] = useState(null);

    const toggleUpgradeToBranchCardModal = (id = null,company =null, service_type_id = null) => {
      setSelectedProspectId(id);
      setSelectedCompany(company)
      setSelectedServiceTypeId(service_type_id);
      setShowUpgradeToBranchModal(!showUpgradeToBranchModal);
    };
    const toggleUpgradeToStoreCardModal = (id = null,company = null) => {
      setSelectedProspectId(id);
      setSelectedCompany(company);
      setShowUpgradeToStoreModal(!showUpgradeToStoreModal);
    };
    const deleteProspect = (id) => {
      props.deleteProspect && props.deleteProspect(id);
    };

    const refreshProspects = () => {
      props.refreshProspects();
    };

    let permissions = getPermissions();

    const TableRow = (props) => {
      const { id, company, main_contact, address, status, service_type_id } = props;

      const statusEnum = {
        proposal_submitted: { color: "warning", label: "Proposal Submitted" },
        proposal_rejected: { color: "danger", label: "Proposal Rejected" },
        contacted: { color: "primary", label: "Contacted" },
        approved: { color: "success", label: " Approved" },
        follow_up_call: { color: "info", label: "Follow-up call" },
        proposal_accepted: { color: "primary", label: "Proposal Accepted" },
      };

      const statusColor = statusEnum[status.status_name].color;
      const statusLabel = statusEnum[status.status_name].label;

      const canUpgrade = ["proposal_submitted", "contacted", "proposal_accepted", "follow_up_call"]

      return (
        <tr className="border-bottom">
          <td>{company}</td>
          <td>
            <span className="fw-normal">{main_contact}</span>
          </td>
          <td>
            <span className="fw-normal">{address.address1}</span>
          </td>
          <td>
            <span className="fw-normal">{address.city}</span>
          </td>
          <td>
            <span className="fw-bold">{address.state}</span>
          </td>
          <td>
            <span className={`fw-bold text-${statusColor}`}>{statusLabel}</span>
          </td>
          { (permissions.includes('updateProspect') || permissions.includes('getProspect') || permissions.includes('getAllNotes') || permissions.includes('getAllAttachments') ||
              permissions.includes('upgradeToStore') || permissions.includes('upgradeToBranch') || permissions.includes('deleteProspect')) &&
            <td className="text-center">
              { (permissions.includes('updateProspect') || permissions.includes('getProspect') || permissions.includes('getAllNotes') || permissions.includes('getAllAttachments') ||
              permissions.includes('upgradeToStore') || permissions.includes('upgradeToBranch') || permissions.includes('deleteProspect')) &&
                <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle
                    as={Button}
                    split
                    variant="link"
                    className="text-dark m-0 p-0"
                  >
                    <DotsHorizontalIcon className="icon icon-xs icon-dark" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-0">
                  { permissions.includes('updateProspect') &&
                    <Dropdown.Item as={Link} to={`/clients/prospects/edit-prospect/${id}`}>
                      <PencilAltIcon className="icon icon-xs me-2" /> Edit
                    </Dropdown.Item>
                  }
                  { permissions.includes('getProspect') &&
                    <Dropdown.Item as={Link} to={`/clients/prospects/view-prospect/${id}`}>
                      <EyeIcon className="icon icon-xs me-2" /> View Details
                    </Dropdown.Item>
                  }
                  { permissions.includes('getAllNotes') &&
                    <Dropdown.Item as={Link} to={`/notes/prospect/${id}`}>
                      <PencilIcon className="icon icon-xs me-2" />  Notes
                    </Dropdown.Item>
                  }
                  { permissions.includes('getAllAttachments') &&
                    <Dropdown.Item as={Link} to={`/attachments/prospect/${id}`}>
                      <PaperClipIcon className="icon icon-xs me-2" /> Attachments
                    </Dropdown.Item>
                  }
                  { permissions.includes('getProspectStatusHistory') &&
                    <Dropdown.Item as={Link} to={`/clients/prospects/view-status-history/${id}/${company}`}>
                      <ClockIcon className="icon icon-xs me-2" /> Status History
                    </Dropdown.Item>
                  }
                    {canUpgrade.includes(status.status_name) &&  (
                      <>
                      { permissions.includes('upgradeToStore') &&
                        <Dropdown.Item onClick={() => toggleUpgradeToStoreCardModal(id,company)}>
                          <OfficeBuildingIcon className="icon icon-xs me-2" /> Upgrade to store
                        </Dropdown.Item>
                      }
                      { permissions.includes('upgradeToBranch') &&
                        <Dropdown.Item onClick={() => toggleUpgradeToBranchCardModal(id, company, service_type_id)}>
                          <LocationMarkerIcon className="icon icon-xs me-2" /> Upgrade to branch
                        </Dropdown.Item>
                      }
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              }
              {/* { permissions.includes('deleteProspect') &&
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip className="m-0">Delete</Tooltip>}
                >
                  <Card.Link className="ms-2" onClick={() => deleteProspect(id)}>
                    <XCircleIcon className="icon icon-xs text-danger" />
                  </Card.Link>
                </OverlayTrigger>
              } */}
            </td>
          }
        </tr>
      );
    };

    return (
      <>
        {showUpgradeToBranchModal && (
          <UpgradeToBranchModel
            show={showUpgradeToBranchModal}
            onHide={toggleUpgradeToBranchCardModal}
            prospectId={selectedProspectId}
            service_type_id ={selectedServiceTypeId}
            company={selectedCompany}
            refreshProspects={refreshProspects}
          />
        )}
        {showUpgradeToStoreModal && (
          <UpgradeToStoreModel
            show={showUpgradeToStoreModal}
            onHide={toggleUpgradeToStoreCardModal}
            prospectId={selectedProspectId}
            company={selectedCompany}
            refreshProspects={refreshProspects}
          />
        )}
        <Card border="0" className="table-wrapper table-responsive shadow">
          <Card.Body>
            <Table hover>
              <thead>
                <tr>
                  <th className="border-gray-200">Company</th>
                  <th className="border-gray-200">Main Contact</th>
                  <th className="border-gray-200">Location</th>
                  <th className="border-gray-200">City</th>
                  <th className="border-gray-200">State</th>
                  <th className="border-gray-200">Status</th>
                  { (permissions.includes('updateProspect') || permissions.includes('getProspect') || permissions.includes('getAllNotes') || permissions.includes('getAllAttachments') ||
                    permissions.includes('upgradeToStore') || permissions.includes('upgradeToBranch') || permissions.includes('deleteProspect')) &&
                    <th className="border-gray-200">Action</th>
                  }
                </tr>
              </thead>
              <tbody className="border-0">
                {currentProspects.map((p) => (
                  <TableRow key={`prospect-${p.id}`} {...p} />
                ))}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
              <Nav>
                <Pagination className="mb-0">
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                  </Pagination.Prev>
                  {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-normal mt-4 mt-lg-0">
                Showing <b>{currentProspects.length}</b> out of <b>{prospects.length}</b> entries
              </small>
            </Card.Footer>
          </Card.Body>
        </Card>
      </>
    );
  };

  export const StatusHistoryTable = ({ prospects = [], refreshed }) => {
    const totalProspects = prospects.length;
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(totalProspects / pageSize);
    const currentProspects = prospects.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const TableRow = ({ id, status_name, formatted_created_at, formatted_updated_at,user,isSelected }) => {
        const statusEnum = {
            proposal_submitted: { color: "warning", label: "Proposal Submitted" },
            proposal_rejected: { color: "danger", label: "Proposal Rejected" },
            contacted: { color: "primary", label: "Contacted" },
            approved: { color: "success", label: "Approved" },
            follow_up_call: { color: "info", label: "Follow-up call" },
            proposal_accepted: { color: "primary", label: "Proposal Accepted" },
        };
        const statusColor = statusEnum[status_name].color;
        const statusLabel = statusEnum[status_name].label;

      return (
        <tr className="border-bottom">
          <td>
            <span className={`fw-bold text-${statusColor}`}>{statusLabel}</span>
          </td>
          <td>
            <span className="fw-normal">{user.name}</span>
          </td>
          <td>
            <span className="fw-normal">{formatted_created_at}</span>
          </td>
          <td>
            <span className="fw-normal">
            {formatted_created_at === formatted_updated_at ? (
                <>
                     <RefreshIcon className="icon icon-xs me-2" /> No updates
                </>
            ) : (
                formatted_updated_at
            )}
            </span>
          </td>
        </tr>
      );
    };

    return (
      <>
        <Card border="0" className="table-wrapper table-responsive shadow">
          <Card.Body>
            <Table hover className="prospect-table align-items-center table-sm">
              <thead>
                <tr>
                  <th className="border-bottom">Status</th>
                  <th className="border-bottom">User</th>
                  <th className="border-bottom">Created At</th>
                  <th className="border-bottom">Updated At</th>
                </tr>
              </thead>
              <tbody className="border-0">
                {currentProspects.map((prospect) => (
                  <TableRow key={`prospect-${prospect.id}`} {...prospect} />
                ))}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
              <Nav>
                <Pagination className="mb-0">
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                  </Pagination.Prev>
                  {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-normal mt-4 mt-lg-0">
                Showing <b>{currentProspects.length}</b> out of <b>{totalProspects}</b> entries
              </small>
            </Card.Footer>
          </Card.Body>
        </Card>
      </>
    );
  };

export const StoresTable = (props) => {
  const { stores = [] } = props;
  const totalStores = stores.length;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const totalPages = Math.ceil(totalStores / pageSize);
  const currentStores = stores.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  let permissions = getPermissions();
  const [showStoreEditModal, setshowStoreEditModel] = useState(false);
  const [showStoreViewModal, setshowStoreViewModel] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [selectedInternalCodeId, setSelectedInternalCodeId] = useState(null);
  const [selectedInternalCodeName, setSelectedInternalCodeName] = useState(null);
  const [selectedStoreStatus,  setSelectedStoreStatus] = useState(null);
  const [selectedStoreName, setSelectedStoreName] = useState(null);
  const [selectedClientSince, setSelectedClientSince] = useState(null);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleEditCardModal = (
        id  = null,
        internal_code_id = null,
        internal_code = null,
        name = null,
        status = null,
        since = null
    ) => {
    setSelectedStoreId(id);
    setSelectedStoreName(name)
    setSelectedStoreStatus(status)
    setSelectedClientSince(since)
    setSelectedInternalCodeId(internal_code_id);
    setSelectedInternalCodeName(internal_code)
    setshowStoreEditModel(!showStoreEditModal);
  };

  const toggleViewCardModal = (id  = null) => {
    setSelectedStoreId(id);
    setshowStoreViewModel(!showStoreViewModal);
  };

//   const deleteStore = (id) => {
//     props.deleteStore && props.deleteStore(id);
//   };

  const refreshStores = () => {
    props.refreshStores()
  }

  const TableRow = (props) => {
    const { id, since, internal_code, internal_code_id, name, total_branches, status } = props;
    const statusEnum = {
      1: { color: "success", label: "Active" },
      0: { color: "warning", label: "Inactive" },
    };
    const statusColor = statusEnum[status].color, statusLabel = statusEnum[status].label;

    return (
      <tr className="border-bottom">
        <td>{internal_code}</td>
        <td>
          <span className="fw-normal">{name}</span>
        </td>
        <td>
          <span className="fw-normal">{since}</span>
        </td>
        <td>
          <span className="fw-normal">{total_branches}</span>
        </td>
        <td>
          <span className={`fw-bold text-${statusColor}`}>
            {statusLabel}
          </span>
        </td>
        { (permissions.includes('updateStore') || permissions.includes('getAllBranches') ||
            permissions.includes('getAllNotes') || permissions.includes('getAllAttachments') || permissions.includes('deleteStore')) &&
          <td className="text-center">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                as={Button}
                split
                variant="link"
                className="text-dark m-0 p-0"
              >
                <DotsHorizontalIcon className="icon icon-xs icon-dark" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-0">
              { permissions.includes('updateStore') &&
                <Dropdown.Item onClick={() => toggleEditCardModal(id,internal_code_id,internal_code,name,status,since)}>
                  <PencilAltIcon className="icon icon-xs me-2" /> Edit
                </Dropdown.Item>
              }
              { permissions.includes('getStore') &&
                <Dropdown.Item onClick={() => toggleViewCardModal(id)}>
                  <EyeIcon className="icon icon-xs me-2" /> View Details
                </Dropdown.Item>
              }
              { permissions.includes('getAllNotes') &&
                <Dropdown.Item as={Link} to={`/notes/store/${id}`}>
                  <PencilIcon className="icon icon-xs me-2" /> Notes
                </Dropdown.Item>
              }
              { permissions.includes('getAllAttachments') &&
                <Dropdown.Item as={Link}  to={`/attachments/store/${id}`}>
                  <PaperClipIcon className="icon icon-xs me-2" /> Attachments
                </Dropdown.Item>
              }
              { permissions.includes('getAllBranches') &&
                <Dropdown.Item as={Link} to={`/clients/stores/${id}/branches`}>
                  <LocationMarkerIcon className="icon icon-xs me-2" /> Branches
                </Dropdown.Item>
              }
              { permissions.includes('getAllContracts') &&
                <Dropdown.Item as={Link} to={`/contracts/store/${id}`}>
                  <DocumentTextIcon className="icon icon-xs me-2" /> Contracts
                </Dropdown.Item>
              }
              </Dropdown.Menu>
            </Dropdown>
            {/* { permissions.includes('deleteStore') &&
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className="m-0">Delete</Tooltip>}
              >
                <Card.Link className="ms-2" onClick={() => deleteStore(id)}>
                  <XCircleIcon className="icon icon-xs text-danger" />
                </Card.Link>
              </OverlayTrigger>
            } */}
          </td>
        }
      </tr>
    );
  };

  return (
    <>
      {showStoreEditModal && (
        <StoreEditModal
          show={showStoreEditModal}
          onHide={toggleEditCardModal}
          storeId={selectedStoreId}
          internalCodeId={selectedInternalCodeId}
          internalCodeName={selectedInternalCodeName}
          storeName={selectedStoreName}
          storeStatus={selectedStoreStatus}
          clientSince={selectedClientSince}
          selectedInternalCodeId={selectedInternalCodeId}
          refreshStores={refreshStores}
        />
      )}
      {showStoreViewModal && (
        <StoreViewModal
          show={showStoreViewModal}
          onHide={toggleViewCardModal}
          storeId={selectedStoreId}
        />
      )}
      <Card border="0" className="table-wrapper table-responsive shadow">
        <Card.Body>
          <Table hover>
            <thead>
              <tr>
                <th className="border-gray-200">Internal Code</th>
                <th className="border-gray-200">Name</th>
                <th className="border-gray-200">Client Since</th>
                <th className="border-gray-200">Total branches</th>
                <th className="border-gray-200">Status</th>
                { (permissions.includes('updateStore') || permissions.includes('getAllBranches') ||
                  permissions.includes('getAllNotes') || permissions.includes('getAllAttachments') || permissions.includes('deleteStore')) &&
                  <th className="border-gray-200">Action</th>
                }
              </tr>
            </thead>
            <tbody className="border-0">
              {currentStores.map((s) => (
                <TableRow key={`store-${s.id}`} {...s} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-0">
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                  Previous
                </Pagination.Prev>
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-normal mt-4 mt-lg-0">
              Showing <b>{currentStores.length}</b> out of <b>{totalStores}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};

export const StoreBranchesTable = (props) => {
  const { branches = [] } = props;
  const totalBranches = branches.length;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const totalPages = Math.ceil(totalBranches / pageSize);
  const currentBranches = branches.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  const permissions = getPermissions();
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

//   const deleteBranch = (id) => {
//     props.deleteBranch && props.deleteBranch(id);
//   };

  const TableRow = (props) => {
    const { id, internal_code, name, address, state, city, service_frequency, status } =
      props;
    const statusEnum = {
      1: { color: "success", label: "Active" },
      0: { color: "warning", label: "Inactive" },
    };
    const statusColor = statusEnum[status].color, statusLabel = statusEnum[status].label;

    return (
      <tr className="border-bottom">
        <td>{internal_code}</td>
        <td>{name}</td>
        <td>
          <span className="fw-normal">{address}</span>
        </td>
        <td>
          <span className="fw-normal">{city}</span>
        </td>
        <td>
          <span className="fw-normal">{state}</span>
        </td>
        <td>
          <span className={`fw-bold text-${statusColor}`}>
            {statusLabel}
          </span>
        </td>
        { (permissions.includes('updateBranch') || permissions.includes('getBranch') || permissions.includes('getAllNotes') || permissions.includes('getAllAttachments') || permissions.includes('deleteBranch')) &&
          <td className="text-center">
            { (permissions.includes('updateBranch') || permissions.includes('getBranch') || permissions.includes('getAllNotes') || permissions.includes('getAllAttachments')) &&
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle
                  as={Button}
                  split
                  variant="link"
                  className="text-dark m-0 p-0"
                >
                <DotsHorizontalIcon className="icon icon-xs icon-dark" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-0">
                { permissions.includes('updateBranch') &&
                  <Dropdown.Item as={Link} to={`/clients/stores/branches/edit-branch/${id}`}>
                    <PencilAltIcon className="icon icon-xs me-2" /> Edit
                  </Dropdown.Item>
                }
                { permissions.includes('getBranch') &&
                  <Dropdown.Item as={Link} to={`/clients/stores/branches/view-branch/${id}`}>
                    <EyeIcon className="icon icon-xs me-2" /> View Details
                  </Dropdown.Item>
                }
                { permissions.includes('getAllNotes') &&
                  <Dropdown.Item as={Link} to={`/notes/branch/${id}`}>
                    <PencilIcon className="icon icon-xs me-2" /> Notes
                  </Dropdown.Item>
                }
                { permissions.includes('getAllAttachments') &&
                  <Dropdown.Item as={Link}  to={`/attachments/branch/${id}`}>
                    <PaperClipIcon className="icon icon-xs me-2" /> Attachments
                  </Dropdown.Item>
                }
                { permissions.includes('getAllContracts') &&
                  <Dropdown.Item as={Link} to={`/contracts/branch/${id}`}>
                    <DocumentTextIcon className="icon icon-xs me-2" /> Contracts
                  </Dropdown.Item>
                }
                </Dropdown.Menu>
              </Dropdown>
            }
            {/* { permissions.includes('deleteBranch') &&
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className="m-0">Delete</Tooltip>}
              >
                <Card.Link className="ms-2" onClick={() => deleteBranch(id)}>
                  <XCircleIcon className="icon icon-xs text-danger" />
                </Card.Link>
              </OverlayTrigger>
            } */}
          </td>
        }
      </tr>
    );
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow">
      <Card.Body>
        <Table hover>
          <thead>
            <tr>
              <th className="border-gray-200">Internal Code</th>
              <th className="border-gray-200">Branch's name</th>
              <th className="border-gray-200">Address</th>
              <th className="border-gray-200">City</th>
              <th className="border-gray-200">State</th>
              {/* <th className="border-gray-200">Service Frequency</th> */}
              <th className="border-gray-200">Status</th>
              { (permissions.includes('updateBranch') || permissions.includes('getBranch') || permissions.includes('getAllNotes') || permissions.includes('getAllAttachments') || permissions.includes('deleteBranch')) &&
                <th className="border-gray-200">Action</th>
              }
            </tr>
          </thead>
          <tbody className="border-0">
            {currentBranches.map((b) => (
              <TableRow key={`branch-${b.id}`} {...b} />
            ))}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-0">
               <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                Previous
              </Pagination.Prev>
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-normal mt-4 mt-lg-0">
            Showing <b>{currentBranches.length}</b> out of <b>{totalBranches}</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

