export const saveToken = (token) => {
    localStorage.setItem('authToken', token);
};
  
export const saveUserName = (userName) => {
  localStorage.setItem('userName', userName);
};

export const savePermissions = (permissions) => {
  localStorage.setItem('permissions', permissions);
};

export const getToken = () => {
  return localStorage.getItem('authToken');
};

export const getUserName = () => {
  return localStorage.getItem('userName');
};

export const getPermissions = () => {
  return localStorage.getItem('permissions');
};
  
export const removeToken = () => {
  localStorage.removeItem('authToken');
};

export const removeUserName = () => {
  localStorage.removeItem('userName');
};

export const removePermissions = () => {
  localStorage.removeItem('permissions');
};