import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RoutesPath } from "../../../../routes";
import {StoreBranchesTable } from "components/Tables";
import api from '../../../../service/api';
import { BASE_URL } from '../../../../helpers/config';
import { useParams } from 'react-router-dom';
import { getPermissions } from "service/authService";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary me-3',
      cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

export default () => {
  const { storeId } = useParams();
  const [branches, setBranches] = useState([]);
  const [store, setStore] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  const selectedBranchesIds = branches.filter(s => s.isSelected).map( s=> s.id);
  const totalBranches = branches.length;
  const allSelected = selectedBranchesIds.length === totalBranches;
  const permissions = getPermissions();

  const fetchData = async () => {
    try {
      const response = await api.get(`${BASE_URL}/branches/${storeId}`);
      let data = Object.values(response.data.data);
      const responseStore = await api.get(`${BASE_URL}/store/${storeId}`);
      setStore(responseStore.data.data);
      const modifiedBranches = data.map(p => ({...p, isSelected: false, show: true }));
      setBranches(modifiedBranches);
    } catch (error) {
      console.log(error);
      await SwalWithBootstrapButtons.fire({
        icon: 'error',
        title: 'Error loading branches',
        text: 'Unable to load branches. Please try again later.',
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value.toLowerCase();

    const modifiedBranches = branches.map(b => {
      const address = b.address.toLowerCase();
      const internalCode = b.internal_code.toLowerCase();
      const city = b.city.toLowerCase();
      const state = b.state.toLowerCase();
      const serviceFrequency = b.service_frequency.toLowerCase();

      const shouldShow = address.includes(newSearchValue)
        || state.includes(newSearchValue)
        || city.includes(newSearchValue)
        || internalCode.includes(newSearchValue)
        || serviceFrequency.includes(newSearchValue);


      return ({ ...b, show: shouldShow });
    });

    setSearchValue(newSearchValue);
    setBranches(modifiedBranches);
  };

  const changeStatusValueFilter = (e) => {
    const newStatusValue = e.target.value;
    const numericStatusValue = newStatusValue === "all" ? "all" : parseInt(newStatusValue, 10);

    const modifiedBranches = branches.map(p => ({
        ...p,
        show: numericStatusValue === "all" || p.status === numericStatusValue
    }));

    setStatusValue(numericStatusValue);
    setBranches(modifiedBranches);
  };

  const filteredStores = branches.filter(b => {
    const matchesStatus = statusValue === "all" || b.status === parseInt(statusValue, 10);
    const matchesSearch = b.name.toLowerCase().includes(searchValue) || b.internal_code.toLowerCase().includes(searchValue)
    ||b.address.toLowerCase().includes(searchValue) || b.city.toLowerCase().includes(searchValue)
    || b.state.toLowerCase().includes(searchValue) || b.service_frequency.toLowerCase().includes(searchValue);

    return matchesStatus && matchesSearch;
  });

//   const deleteBranch = async (id) => {
//     const result = await SwalWithBootstrapButtons.fire({
//       icon: "error",
//       title: "Are you sure?",
//       text: "Do you really want to delete this record?",
//       showCancelButton: true,
//       confirmButtonText: "Yes",
//       cancelButtonText: "Cancel"
//     });

//     if (result.isConfirmed) {
//       try {
//         await api.delete(`${BASE_URL}/branch/${id}`);
//       } catch (error) {
//         console.error('Error submitting form:', error);
//       }
//       const newBranches = branches.filter(f => f.id !== id);
//       const confirmMessage = "The branch has been deleted.";

//       setBranches(newBranches);
//       await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
//     }
//   };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>Clients</Breadcrumb.Item>
            <Breadcrumb.Item active>
              <Link to={RoutesPath.Stores.path}>Store</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>branches</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Store's branches</h4>
          <p className="mb-0">{store.name}</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          { store.status === 1 && permissions.includes('createBranch') &&
            <Button variant="gray-800" size="sm" className="d-inline-flex align-items-center" as={Link} to={`/clients/stores/${storeId}/branches/new-branch`}>
              <PlusIcon className="icon icon-xs me-2" /> New Branch
            </Button>
          }
          <ButtonGroup className="ms-2 ms-lg-3">
            <Button variant="outline-gray-600" size="sm">Import</Button>
            <Button variant="outline-gray-600" size="sm">Export</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="d-flex justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-400">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search branches"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusValue} className="fmxw-200 d-none d-md-inline" onChange={changeStatusValueFilter}>
              <option value="all">All</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </Form.Select>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                <CogIcon className="icon icon-sm" />
                <span className="visually-hidden">Toggle Dropdown</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                <small className="ps-3 fw-bold text-dark">Show</small>
                <Dropdown.Item className="d-flex align-items-center fw-bold">
                  10 <CheckIcon className="icon icon-xxs ms-auto" />
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <StoreBranchesTable
        branches={filteredStores}
        // deleteBranch={deleteBranch}
        refreshBranches={fetchData}
      />
    </>
  );
};
