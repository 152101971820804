import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RoutesPath } from "../../../routes";
import {StoresTable } from "components/Tables";
import api from '../../../service/api';
import { BASE_URL } from '../../../helpers/config';
import { getPermissions } from "service/authService";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary me-3',
      cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

const Stores = () => {
  const [stores, setStores] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  const selectedStoresIds = stores.filter(s => s.isSelected).map( s=> s.id);
  const totalStores = stores.length;
  const allSelected = selectedStoresIds.length === totalStores;
  const permissions = getPermissions();

  const fetchData = async () => {
    try {
      const response = await api.get(`${BASE_URL}/stores`);
      let data = Object.values(response.data.data);

      const modifiedStores = data.map(p => ({...p, isSelected: false, show: true }));
      setStores(modifiedStores);

    } catch (error) {
      console.log(error);
      await SwalWithBootstrapButtons.fire({
          icon: 'error',
          title: 'Error loading Stores',
          text: 'Unable to load Stores. Please try again later.',
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value.toLowerCase();

    const newStores = stores.map(s => {
      const name = s.name.toLowerCase();
      const internalCode = s.internal_code.toLowerCase();
      const totalBranches = String(s.total_branches);
      const since = String(s.since);
      const status = String(s.status);

      const shouldShow = name.includes(newSearchValue)
        || totalBranches.includes(newSearchValue)
        || since.includes(newSearchValue)
        || status.includes(newSearchValue)
        || internalCode.includes(newSearchValue);

      return { ...s, show: shouldShow };
    });

    setSearchValue(newSearchValue);
    setStores(newStores);
  };

  const changeStatusValueFilter = (e) => {
    const newStatusValue = e.target.value;
    const numericStatusValue = newStatusValue === "all" ? "all" : parseInt(newStatusValue, 10);

    const newStores = stores.map(p => ({
        ...p,
        show: numericStatusValue === "all" || p.status === numericStatusValue
    }));

    setStatusValue(numericStatusValue);
    setStores(newStores);
  };

  const filteredStores = stores.filter(s => {
    const matchesStatus = statusValue === "all" || s.status === parseInt(statusValue, 10);
    const matchesSearch = s.name.toLowerCase().includes(searchValue)
      || s.internal_code.toLowerCase().includes(searchValue)
      || s.since.toLowerCase().includes(searchValue);

    return matchesStatus && matchesSearch;
  });

//   const deleteStore = async (id) => {
//     const result = await SwalWithBootstrapButtons.fire({
//       icon: "error",
//       title: "Are you sure?",
//       text: "Do you really want to delete this record?",
//       showCancelButton: true,
//       confirmButtonText: "Yes",
//       cancelButtonText: "Cancel"
//     });

//     if (result.isConfirmed) {
//       try {
//         await api.delete(`${BASE_URL}/store/${id}`);
//       } catch (error) {
//         console.error('Error submitting form:', error);
//       }
//       const newStores = stores.filter(f => f.id !== id);
//       const confirmMessage = "The store has been deleted.";

//       setStores(newStores);
//       await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
//     }
//   };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>Clients</Breadcrumb.Item>
            <Breadcrumb.Item active>Stores</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Stores</h4>
          <p className="mb-0">Manage Stores</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          { permissions.includes('createStore') &&
            <Button variant="gray-800" size="sm" className="d-inline-flex align-items-center" as={Link} to={RoutesPath.NewStoreForm.path}>
              <PlusIcon className="icon icon-xs me-2" /> New Store
            </Button>
          }
          <ButtonGroup className="ms-2 ms-lg-3">
            <Button variant="outline-gray-600" size="sm">Import</Button>
            <Button variant="outline-gray-600" size="sm">Export</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="d-flex justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-400">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search stores"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusValue} className="fmxw-200 d-none d-md-inline" onChange={changeStatusValueFilter}>
              <option value="all">All</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </Form.Select>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                <CogIcon className="icon icon-sm" />
                <span className="visually-hidden">Toggle Dropdown</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                <small className="ps-3 fw-bold text-dark">Show</small>
                <Dropdown.Item className="d-flex align-items-center fw-bold">
                  10 <CheckIcon className="icon icon-xxs ms-auto" />
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <StoresTable
        stores={filteredStores}
        // deleteStore={deleteStore}
        refreshStores={fetchData}
      />
    </>
  );
};

export default Stores;
