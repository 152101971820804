import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

const AttachmentDescriptionInput = ({ description, onChange, isDescriptionRequired, isDisabled }) => {
    return (
    <Form.Group id="title">
      <Form.Label>Attachment Description
        <span className="text-danger"> * </span>
      </Form.Label>
      <InputGroup>
        <Form.Control
          as="textarea"
          rows="4"
          placeholder="Type down a description..."
          required
          name="description"
          value={description}
          onChange={onChange}
          disabled={isDisabled}
          maxLength={150}
        />
      </InputGroup>
      <Form.Text className="text-muted">
        {description.length}/150 characters
      </Form.Text>
    </Form.Group>
  );
};

export default AttachmentDescriptionInput;
