import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import InputMask from 'react-input-mask';

const ZipCodeInput = ({ formData, setFormData, mode }) => {
    const [error, setError] = useState('');

    useEffect(() => {
        validateZipCode(formData.zipCode);
    }, [formData.zipCode]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        const cleanedValue = value.replace(/\D/g, '');

        setFormData({ ...formData, [name]: cleanedValue });
        validateZipCode(cleanedValue);
    };

    const validateZipCode = (cleanedValue) => {
        if (!cleanedValue) {
            setError('');
            return;
        }

        if (cleanedValue.length != 5 ) {
            setError('Please enter a valid ZIP code (format: 00000)');
        } else {
            setError('');
        }
    };

    const RequiredLabel = ({ label }) => (
        <Form.Label>
            {label}
            <span className="text-danger"> * </span>
        </Form.Label>
    );

    return (
        <Form.Group id="zipCode">
            <RequiredLabel label="Zip Code" />
            <InputGroup>
                {mode === 'view' ? (
                    <Form.Control
                        type="text"
                        value={formData.zipCode}
                        readOnly
                    />
                ) : (
                    <InputMask
                        mask="99999"
                        value={formData.zipCode}
                        onChange={handleChange}
                    >
                        {(inputProps) => (
                            <Form.Control
                                {...inputProps}
                                required={mode === 'create' || mode === 'edit'}
                                type="text"
                                name="zipCode"
                            />
                        )}
                    </InputMask>
                )}
            </InputGroup>
            {error && <Form.Text className="text-danger">{error}</Form.Text>}
        </Form.Group>
    );
};

export default ZipCodeInput;
