import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import api from '../../../service/api';
import { BASE_URL } from '../../../helpers/config';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

const SelectProspectStatus = ({ selectedProspectStatus, onProspectStatusChange, mode }) => {
  const [prospectStatus, setProspectStatus] = useState([]);

  useEffect(() => {
    const fetchProspectStatus = async () => {
        try {
            const response = await api.get(`${BASE_URL}/prospectStatus`);
            setProspectStatus(response.data.data);
        } catch (error) {
            console.error('Error fetching prospects statuses:', error);
            await SwalWithBootstrapButtons.fire(
                "Error loading prospects statuses",
                error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue loading the prospects statuses. Please try again later.",
                "error"
            );
        }
    };

    fetchProspectStatus();
  }, []);

  const capitalizeFirstLetter = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <Form.Group id="prospectStatus">
      <InputGroup>
        {mode === 'view' ? (
          <Form.Control
            type="text"
            value={(() => {
                  if (selectedProspectStatus.toLowerCase().includes('submitted')) {
                     return 'Proposal Submitted';
                  } else if (selectedProspectStatus.toLowerCase().includes('rejected')) {
                     return 'Proposal Rejected';
                  } else if (selectedProspectStatus.toLowerCase().includes('accepted')) {
                     return 'Proposal Accepted';
                  } else if (selectedProspectStatus.toLowerCase().includes("follow")) {
                     return 'Follow-up call';
                  }
              })()}
            readOnly
            disabled
          />
        ) : (
            <Form.Select
                name="status"
                value={selectedProspectStatus}
                onChange={onProspectStatusChange}
                required
            >
                {mode !== 'edit' && <option value="">Select a status</option>}
                {prospectStatus.map((status) => {
               let displayStatus = capitalizeFirstLetter(status.status_name); // Default case

               if (status.status_name.toLowerCase().includes('submitted')) {
                 displayStatus = 'Proposal Submitted';
               } else if (status.status_name.toLowerCase().includes('rejected')) {
                 displayStatus = 'Proposal Rejected';
               } else if (status.status_name.toLowerCase().includes('accepted')) {
                 displayStatus = 'Proposal Accepted';
               } else if (status.status_name.toLowerCase().includes("follow")) {
                 displayStatus = "Follow-up call";
               }
                return (
                    <option key={status.id} value={status.id}>
                    {displayStatus}
                    </option>
                );
                })}
            </Form.Select>
        )}
      </InputGroup>
    </Form.Group>
  );
};

export default SelectProspectStatus;
