import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const EmailInput = ({ value, onChange, mode }) => {
  const [emailError, setEmailError] = useState('');

    const RequiredLabel = ({ label }) => (
        <Form.Label>
            {label}
            <span className="text-danger"> * </span>
        </Form.Label>
    );

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        return emailRegex.test(email);
    };

    const handleChange = (e) => {
        const emailValue = e.target.value;
        onChange(e);

        if (emailValue && !validateEmail(emailValue)) {
            setEmailError('Please enter a valid email address: username@example.com');
        } else {
            setEmailError('');
        }
    };
    return (
        <Form.Group id="email" controlId="email">
            <RequiredLabel label="Email" />
            {mode === 'view' ? (
                <Form.Control
                    type="email"
                    value={value}
                    readOnly
                    disabled
                />
            ) : (
                <Form.Control
                    required
                    type="email"
                    name="email"
                    value={value}
                    onChange={handleChange}
                    placeholder="user@company.com"
                    maxLength={100}
                    isInvalid={emailError !== ''}
                />
            )}

            {emailError && (
                <Form.Control.Feedback type="invalid">
                    {emailError}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export default EmailInput;
