import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ProspectsTable } from "components/Tables";
import { BASE_URL } from '../../../helpers/config';
import { RoutesPath } from "routes";
import api from '../../../service/api';
import { getPermissions } from "service/authService";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary me-3',
      cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

const Prospects = () => {
  const [prospects, setProspects] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  
  const fetchData = async () => {
    try {
      const response = await api.get(`${BASE_URL}/prospects`);
      let data = Object.values(response.data.data);

      const modifiedProspects = data.map(p => ({...p, isSelected: false, show: true }));
      setProspects(modifiedProspects);

    } catch (error) {
      console.log(error);
      await SwalWithBootstrapButtons.fire({
        icon: 'error',
        title: 'Error loading prospects',
        text: 'Unable to load prospects. Please try again later.',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value.toLowerCase();
    const newProspects = prospects.map(p => {
        const company = p.company.toLowerCase();
        const address = p.address.address1.toLowerCase();
        const city = p.address.city.toLowerCase();
        const state = p.address.state.toLowerCase();
        const mainContact = p.main_contact.toLowerCase();
        const status = p.status.status_name.toLowerCase();
        const shouldShow = company.includes(newSearchValue)
            || address.includes(newSearchValue)
            || city.includes(newSearchValue)
            || state.includes(newSearchValue)
            || mainContact.includes(newSearchValue)
            || status.includes(newSearchValue);

        return { ...p, show: shouldShow };
    });

    setSearchValue(newSearchValue);
    setProspects(newProspects);
};

  const changeStatusValueFilter = (e) => {
      const newStatusValue = e.target.value.toLowerCase();
      const newProspects = prospects.map(p => {
        const statusMatches = p.status?.status_name.toLowerCase() === newStatusValue;
        return {
          ...p,
          show: newStatusValue === "all" || statusMatches
        };
      });
      setStatusValue(newStatusValue);
      setProspects(newProspects);
  };


  const filteredProspects = prospects.filter(p => {
    const matchesStatus = statusValue === "all" || p.status.status_name === statusValue;
    const matchesSearch = p.company.toLowerCase().includes(searchValue)
      || p.address.address1.toLowerCase().includes(searchValue)
      || p.address.city.toLowerCase().includes(searchValue)
      || p.address.state.toLowerCase().includes(searchValue)
      || p.main_contact.toLowerCase().includes(searchValue)
      || p.status.status_name.toLowerCase().includes(searchValue);

    return matchesStatus && matchesSearch;
  });

//   const deleteProspect = async (id) => {
//     const result = await SwalWithBootstrapButtons.fire({
//       icon: "error",
//       title: "Are you sure?",
//       text: "Do you really want to delete this record?",
//       showCancelButton: true,
//       confirmButtonText: "Yes",
//       cancelButtonText: "Cancel"
//     });

//     if (result.isConfirmed) {
//       try {

//         await api.delete(`${BASE_URL}/prospect/${id}`);
//         const newProspects = prospects.filter(f => f.id !== id);
//         const confirmMessage = "The prospect has been deleted.";
//         setProspects(newProspects);
//         await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');

//       } catch (error) {
//         console.error('Error submitting form:', error);
//         await SwalWithBootstrapButtons.fire({
//             icon: 'error',
//             title: 'Error deleting prospect',
//             text: 'Unable to delete prospect. Please try again later.',
//           });
//       }
//     }
//   };

  let permissions = getPermissions();
  
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>Clients</Breadcrumb.Item>
            <Breadcrumb.Item active>Prospects</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Prospects</h4>
          <p className="mb-0">Manage prospects</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          { permissions.includes('createProspect') &&
            <Button variant="gray-800" size="sm" className="d-inline-flex align-items-center" as={Link} to={RoutesPath.NewProspectForm.path}>
              <PlusIcon className="icon icon-xs me-2" /> New Prospect
            </Button>
          }
          <ButtonGroup className="ms-2 ms-lg-3">
            <Button variant="outline-gray-600" size="sm">Import</Button>
            <Button variant="outline-gray-600" size="sm">Export</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="d-flex justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-400">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search prospects"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusValue} className="fmxw-200 d-none d-md-inline" onChange={changeStatusValueFilter}>
              <option value="all">All</option>
              <option value="approved">Approved</option>
              <option value="contacted">Contacted</option>
              <option value="follow_up_call">Follow-up call</option>
              <option value="proposal_accepted">Proposal Accepted</option>
              <option value="proposal_rejected">Proposal Rejected</option>
              <option value="proposal_submitted">Proposal Submitted</option>
            </Form.Select>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                <CogIcon className="icon icon-sm" />
                <span className="visually-hidden">Toggle Dropdown</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                <small className="ps-3 fw-bold text-dark">Show</small>
                <Dropdown.Item className="d-flex align-items-center fw-bold">
                  10 <CheckIcon className="icon icon-xxs ms-auto" />
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <ProspectsTable
        prospects={filteredProspects}
        // deleteProspect={deleteProspect}
        refreshProspects={fetchData}
      />
    </>
  );
};

export default Prospects;
