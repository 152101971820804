import React, { useState, useEffect }  from "react";
import { Row } from "react-bootstrap";
import {ViewStoreBranchInfoForm } from "../../../../components/Forms";
import { useParams } from 'react-router-dom';
import api from '../../../../service/api';
import { BASE_URL } from '../../../../helpers/config';

export  const ViewStoreBranchForm = () => {
  const { id } = useParams();
  const [branch, setBranch] = useState(null);
  const [loading, setLoading] = useState(true); // Estado de carregamento

  useEffect(() => {
    const fetchData = async () => {
      try {   
        const response = await api.get(`${BASE_URL}/branch/${id}`);
        setBranch(response.data.data);
        setLoading(false);

      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]); 

  if (loading) {
  
  }

  return (
    <>
      <Row>
        {branch ? <ViewStoreBranchInfoForm branch={branch} /> : null}
      </Row>
    </>
  );
};





