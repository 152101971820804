import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Col, Row, Form, Button, InputGroup } from 'react-bootstrap';
import { NoteCardWidget } from "../components/Widgets";
import { ArchiveIcon, PlusIcon, CheckCircleIcon, ChevronRightIcon, ChevronLeftIcon, FolderOpenIcon } from "@heroicons/react/solid";
import { CreateNewNoteModel } from "components/Modals";
import { BASE_URL } from '../helpers/config';
import { useParams } from 'react-router-dom';
import { Tooltip, ButtonGroup, OverlayTrigger } from 'react-bootstrap';
import api from '../service/api';
import { getPermissions } from "service/authService";

const ArchiveIconHtml = ReactDOMServer.renderToString(
  <ArchiveIcon className="h-50 w-auto" />
);
const SwalWithBootstrapButtons = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-3",
      cancelButton: "btn btn-gray",
    },
    buttonsStyling: false,
  })
);

const NotesComponent = () => {
  const [notes, setNotes] = useState([]);
  const [allNotes, setAllNotes] = useState([]);
  const { entity, entityId } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [showCreateCardModal, setShowCreateCardModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastNote = currentPage * itemsPerPage;
  const indexOfFirstNote = indexOfLastNote - itemsPerPage;
  const currentNotes = notes.slice(indexOfFirstNote, indexOfLastNote);
  const totalPages = Math.ceil(notes.length / itemsPerPage);

  let permissions = getPermissions();

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleNoteSelection = () => {
    const selectedIds = notes.filter(n => n.isSelected).map(n => n.status);

    const hasArchived = selectedIds.includes("archived");
    const hasRegistered = selectedIds.includes("registered");
    const hasDone = selectedIds.includes("done")
    if (
        (hasRegistered && hasArchived) ||
        (hasRegistered && hasDone) ||
        (hasRegistered && hasArchived && hasDone)
    ) {
      setMessage('You cannot perform this action when notes with conflicting statuses are selected.');
      setShowAlert(true);
    } else {
      setMessage('');
      setShowAlert(false);
    }
  };

  useEffect(() => {
    handleNoteSelection();
  }, [notes]);

  const selectNote = (note_id, isSelected) => {
    setNotes((prevNotes) =>
      prevNotes.map((n) =>
        n.note_id === note_id ? { ...n, isSelected } : n
      )
    );
  };

//   useEffect(() => {
//     if (entity && entityId) {
//       fetchNotes();
//       handleNoteSelection();
//     }
//   }, [entity, entityId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
      const response = await api.get(`${BASE_URL}/notes/${entity}/${entityId}`);
        let data = Object.values(response.data.data);
        const modifiedNotes = data.map(n => ({ ...n, isSelected: false, show: true }));
        setNotes(modifiedNotes);
        setAllNotes(modifiedNotes);
      } catch (error) {
        console.log(error);
        await SwalWithBootstrapButtons.fire({
            icon: 'error',
            title: 'Error loading notes',
            text: 'Unable to load notes. Please try again later.',
          });
      }
    };

    if (entity && entityId) {
      fetchData();
    }
  }, [entity, entityId]);

  const fetchNotes = async () => {
    try {
      const response = await api.get(`${BASE_URL}/notes/${entity}/${entityId}`);
      const data = Object.values(response.data.data);
      const modifiedNotes = data.map(n => ({ ...n, isSelected: false, show: true }));
      setNotes(modifiedNotes);
      setAllNotes(modifiedNotes);
    } catch (error) {
      console.error(error);
      await SwalWithBootstrapButtons.fire({
        icon: 'error',
        title: 'Error loading notes',
        text: 'Unable to load notes. Please try again later.',
      });
    }
  };

  const toggleCreateCardModal = () => {
    setShowCreateCardModal(!showCreateCardModal);
  };

  const handleNoteCreation = () => {
    fetchNotes();
  };

  const archiveSelectedNotes = async () => {
    const notesNr = selectedNotesIds.length;
    const textMessage =
      notesNr === 1
        ? "Do you really want to archive this note?"
        : "Do you really want to archive these notes?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      iconHtml: ArchiveIconHtml,
      title: "Are you sure?",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await api.put(`${BASE_URL}/notes/status`, {
          ids: selectedNotesIds,
          status: "archived"
        });

        const confirmMessage =
          notesNr === 1
            ? "The note has been archived."
            : "The notes have been archived.";

        await fetchNotes();
        await SwalWithBootstrapButtons.fire(
          "Archived",
          confirmMessage,
          "success"
        );
      } catch (error) {
        console.error('Error submitting form:', error);
        await SwalWithBootstrapButtons.fire(
          "Error updating note",
          error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue updating the note status. Please try again later.",
          "error"
        );
      }
    }
  };

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value.toLowerCase();
    setSearchValue(newSearchValue);

    const filteredNotes = allNotes.filter((n) => {
      const note_title = n.note_title.toLowerCase();
      const description = n.description.toLowerCase();
      const date = n.created_at.toLowerCase();
      const user = n.user_name.toLowerCase();
      const status = n.status.toLowerCase();
      return (
        note_title.includes(newSearchValue) ||
        description.includes(newSearchValue) ||
        status.includes(newSearchValue) ||
        date.includes(newSearchValue) ||
        user.includes(newSearchValue)
      );
    });

    setNotes(filteredNotes);
    setCurrentPage(1);
  };

  const selectedNotesIds = notes.filter((n) => n.isSelected).map((n) => n.note_id);
  const disableMenu = selectedNotesIds.length === 0

  const markSelectedNotesAsDone = async () => {
    const notesNr = selectedNotesIds.length;
    const textMessage =
      notesNr === 1
        ? "Do you really want to mark this note as done?"
        : "Do you really want to mark these notes as done?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      iconHtml: ArchiveIconHtml,
      title: "Are you sure?",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await api.put(`${BASE_URL}/notes/status`, {
          ids: selectedNotesIds,
          status: "done"
        });
        await fetchNotes();

        const confirmMessage =
          notesNr === 1
            ? "The note has been marked as done."
            : "The notes have been marked as done";

        await SwalWithBootstrapButtons.fire(
          "Marked as done",
          confirmMessage,
          "success"
        );
      } catch (error) {
        console.error('Error submitting form:', error);
        await SwalWithBootstrapButtons.fire(
          "Error updating note",
          error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue updating the note status. Please try again later.",
          "error"
        );
      }
    }
  };

  const restoreSelectedNotes = async () => {
    const notesNr = selectedNotesIds.length;
    const textMessage =
      notesNr === 1
        ? "Do you really want to restore this note?"
        : "Do you really want to restore these notes?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      iconHtml: ArchiveIconHtml,
      title: "Are you sure?",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await api.put(`${BASE_URL}/notes/status`, {
          ids: selectedNotesIds,
          status: "registered"
        });
        await fetchNotes();

        const confirmMessage =
          notesNr === 1
            ? "The note has been restored to its original status."
            : "The notes have been restored to their original status";

        await SwalWithBootstrapButtons.fire(
          "Restored",
          confirmMessage,
          "success"
        );
      } catch (error) {
        console.error('Error updating note(s):', error);
        await SwalWithBootstrapButtons.fire(
          "Error updating note(s)",
          error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue updating the note status. Please try again later.",
          "error"
        );
      }
    }
  };

  const toggleStatus = async (note_id, status) => {
    try {
      const newStatus = status.includes("Archived") ? "archived" :
        status.includes("Done") ? "done" :
          status.includes("Registered") ? "registered" :
            "unknown";

      const result = await SwalWithBootstrapButtons.fire({
        icon: "question",
        iconHtml: ArchiveIconHtml,
        title: "Are you sure?",
        text: "Do you really want to proceed with this action?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      });
      if (result.isConfirmed) {
        await api.put(`${BASE_URL}/notes/status`, {
          ids: [note_id],
          status: newStatus
        });

        await fetchNotes();

        if (newStatus === "registered") {
          await SwalWithBootstrapButtons.fire(
            "Note successfully restored to its original status!",
            "",
            "success"
          );
        } else {
          await SwalWithBootstrapButtons.fire(
            `Successfully marked as ${newStatus}`,
            "",
            "success"
          );
        }
      }
    } catch (error) {
      console.error('Error updating status:', error);
      await SwalWithBootstrapButtons.fire(
        "Error updating note",
        error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue updating the note status. Please try again later.",
        "error"
      );
    }
  };

  const deleteNotes = async (note_ids) => {
    const notesNr = note_ids.length;
    const textMessage =
      notesNr === 1
        ? "Do you really want to delete this note?"
        : `Do you really want to delete these ${notesNr} notes?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Are you sure?",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      const newNotes = notes.filter((n) => !note_ids.includes(n.note_id));
      const confirmMessage =
        notesNr === 1
          ? "The note has been deleted."
          : "The notes have been deleted.";

      setNotes(newNotes);
      await SwalWithBootstrapButtons.fire("Deleted", confirmMessage, "success");
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    setMessage(''); // Clear the message if needed
    setNotes(prevNotes =>
        prevNotes.map(n => ({...n,isSelected:false}))
    )
  };

  return (
    <>
      {showAlert && (
        <div className="alert alert-warning d-flex justify-content-between align-items-center">
          <span>{message}</span>
          <button className="btn-close" onClick={handleAlertClose} aria-label="Close"></button>
        </div>
      )}
      {showCreateCardModal && (
        <CreateNewNoteModel
          show={showCreateCardModal}
          onHide={toggleCreateCardModal}
          entityName={entity}
          entityId={entityId}
          refreshNotes={handleNoteCreation}
        />
      )}

      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
          <div className="me-lg-3">
            { permissions.includes('createNote') &&
              <Button
                variant="secondary"
                className="d-inline-flex align-items-center me-2"
                onClick={toggleCreateCardModal}
              >
                <PlusIcon className="icon icon-xs me-2" /> Note
              </Button>
            }
          </div>
          <ButtonGroup>
          {!showAlert && permissions.includes('updateStatusNote') && (
              <>
                <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-0">Archive</Tooltip>}>
                    <Button
                        variant="gray-800"
                        disabled={disableMenu || !notes.some(n => n.isSelected && n.status === "registered")}
                        onClick={archiveSelectedNotes}
                    >
                        <ArchiveIcon className="icon icon-xs" />
                    </Button>
                </OverlayTrigger>

                <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-0">Mark as done</Tooltip>}>
                    <Button
                        variant="gray-800"
                        disabled={disableMenu || !notes.some(n => n.isSelected && n.status === "registered")}
                        onClick={markSelectedNotesAsDone}
                    >
                        <CheckCircleIcon className="icon icon-xs" />
                    </Button>
                </OverlayTrigger>

                <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-0">Restore</Tooltip>}>
                    <Button
                        variant="gray-800"
                        disabled={disableMenu || !notes.some(n => n.isSelected && (n.status === "archived" || n.status === "done"))}
                        onClick={restoreSelectedNotes}
                    >
                        <FolderOpenIcon className="icon icon-xs" />
                    </Button>
                </OverlayTrigger>
                </>
             )}
        </ButtonGroup>
        </Col>

        <Col xs={12} lg={6}>
          <InputGroup className="d-flex justify-content-lg-end">
            <Form.Control
              type="text"
              placeholder="Search for notes here"
              className="w-100 fmxw-300"
              value={searchValue}
              onChange={changeSearchValue}
            />
          </InputGroup>
        </Col>
      </div>

      <div className="task-wrapper border bg-white border-light shadow-sm py-1 rounded">
        {currentNotes.filter((n) => n.show).map((n) => (
          <NoteCardWidget
            {...n}
            key={`note-${n.note_id}`}
            selectNote={selectNote}
            toggleStatus={toggleStatus}
            deleteNote={(note_id) => deleteNotes([note_id])}
          />
        ))}

        <Row className="d-flex align-items-center p-4">
          <Col xs={7} className="mt-1">
            Showing {indexOfFirstNote + 1} - {Math.min(indexOfLastNote, notes.length)} of {notes.length}
          </Col>
          <Col xs={5}>
            <ButtonGroup className="float-end">
              <Button variant="light" onClick={prevPage} disabled={currentPage === 1}>
                <ChevronLeftIcon className="icon icon-xs" />
              </Button>
              <Button variant="primary" onClick={nextPage} disabled={currentPage >= Math.ceil(notes.length / itemsPerPage)}>
                <ChevronRightIcon className="icon icon-xs" />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NotesComponent;
