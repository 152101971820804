import React from 'react';
import { Form,InputGroup } from 'react-bootstrap';

const SelectStatus = ({ value, onChange, mode }) => {
  const isViewMode = mode === 'view';
  const isEdid = mode == 'edit';

  return (
    <Form.Group className="mb-2">
       {isViewMode ? (
        <>
          <Form.Label>Status</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              value={value || ""}
              disabled
            />
          </InputGroup>
        </>
       ):(
        <>
        <Form.Label>Status<span className="text-danger"> * </span></Form.Label>

        <Form.Select
          name='status'
          value={value}
          onChange={onChange}
          required
        >
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </Form.Select>
        </>
      )}
    </Form.Group>
  );
};

export default SelectStatus;
