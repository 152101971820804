import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

const NameInput = ({ value, onChange, mode}) => {
  return (
    <Form.Group id="name">
      <Form.Label>
        Name <span className="text-danger"> * </span>
      </Form.Label>
      <InputGroup>
        {mode === 'view' ? (
          <Form.Control
            type="text"
            value={value}
            readOnly
          />
          ) : (
          <Form.Control
            type="text"
            name="name"
            value={value}
            onChange={onChange}
            maxLength={100}
            required
          />
        )}
      </InputGroup>
    </Form.Group>
  );
};

export default NameInput;
