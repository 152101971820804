import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import api from '../../../service/api';
import { BASE_URL } from '../../../helpers/config';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

const SelectServiceType = ({ selectedServiceType, onServiceTypeChange, mode }) => {
    const [serviceTypes, setServiceTypes] = useState([]);

    useEffect(() => {
        const fetchServiceTypes = async () => {
            try {
                const response = await api.get(`${BASE_URL}/serviceType`);
                setServiceTypes(response.data.data);
            } catch (error) {
                console.error('Error fetching service types:', error);
                await SwalWithBootstrapButtons.fire(
                    "Error loading service types",
                    error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue loading the service types. Please try again later.",
                    "error"
                );
            }
        };

        fetchServiceTypes();
    }, []);

    return (
        <Form.Group id="serviceType">
            <Form.Label>
                Service Type  <span className="text-danger"> * </span>
            </Form.Label>

                <InputGroup>
                    {mode === 'view' ? (
                        <Form.Control
                            type="text"
                            value={selectedServiceType}
                            readOnly
                            disabled
                        />
                    ): (
                    <Form.Select
                        name="serviceType"
                        value={selectedServiceType}
                        onChange={onServiceTypeChange}
                        required
                    >
                        {mode !== 'edit' && <option value="">Select a service type</option>} {/* Exibe a opção apenas se não for 'edit' */}
                        {serviceTypes.map((type) => (
                            <option key={type.id} value={type.id}>
                                {type.service_type_name}
                            </option>
                        ))}
                    </Form.Select>
                )}
            </InputGroup>
        </Form.Group>
    );
};

export default SelectServiceType;
