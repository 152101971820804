import React, { useState, useEffect }  from "react";
import { Row } from "react-bootstrap";
import {CardUserDetailsForm } from "../../components/Forms";
import { useParams } from 'react-router-dom';
import api from '../../service/api';
import { BASE_URL } from '../../helpers/config';

export const UpdateUserForm = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`${BASE_URL}/user/${id}`);
        setUser(response.data.data);
        setLoading(false);

      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
  
  }

  return (
    <>
      <Row>
        {user ? <CardUserDetailsForm user={user} /> : null}
      </Row>
    </>
  );
};
