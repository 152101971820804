import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

const CityInput = ({ value, onChange, mode }) => {
  return (
    <Form.Group id="city">
      <Form.Label>
       City<span className="text-danger"> * </span>
      </Form.Label>
      <InputGroup>
        {mode === 'view' ? (
          <Form.Control
            type="text"
            value={value}
            readOnly
          />
        ) : (
          <Form.Control
            required
            type="text"
            name="city"
            value={value}
            onChange={(e) => onChange(e)}
            maxLength={100}
          />
        )}
      </InputGroup>
    </Form.Group>
  );
};

export default CityInput;
