import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../helpers/config';
import { LockClosedIcon, UserIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, Container, InputGroup, Alert,Card } from 'react-bootstrap';
import { saveToken, saveUserName } from '../service/authService';
import { RoutesPath } from "routes";
import { Link } from 'react-router-dom';
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";

const maskPhoneNumber = (number) => {
  if (!number) return '';
  const cleaned = number.replace(/\D/g, '');
  if (cleaned.length <= 3) return `(${cleaned}`;
  if (cleaned.length <= 6) return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
};

export default () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const [missingRequirements, setMissingRequirements] = useState({
    minLength: true,
    number: true,
    uppercase: true,
    specialChar: true
  });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = useParams();

  const email = searchParams.get('email');
  const identifier = searchParams.get('identifier');
  const token = searchParams.get('token');
  const requiresPasswordChange = searchParams.get('requiresPasswordChange');
  const message = searchParams.get('message');
  const [redirecting, setRedirecting] = useState(false);
  const [redirectMessage, setRedirectMessage] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");

  // Verificar se o "identifier" é um telefone válido
  const [isPhone, setIsPhone] = useState(false);
  useEffect(() => {
    if (identifier && /^\d{10}$/.test(identifier)) {
      setIsPhone(true);
    } else {
      setIsPhone(false);
    }
  }, [identifier]);

  // Função para atualizar os requisitos de senha
  const updatePasswordRequirements = (password) => {
    const minLength = 8;
    const maxLength = 12;
    const regexNumber = /[0-9]/;
    const regexUpperCase = /[A-Z]/;
    const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    setMissingRequirements({
      minLength: password.length < minLength || password.length > maxLength,
      number: !regexNumber.test(password),
      uppercase: !regexUpperCase.test(password),
      specialChar: !regexSpecialChar.test(password)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (missingRequirements.minLength || missingRequirements.number || missingRequirements.uppercase || missingRequirements.specialChar) {
      return; // Não envia o formulário se houver requisitos não atendidos
    }

    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      let resp;

      if (requiresPasswordChange) {
        const requestFirstLogin = {
          new_password: password,
          new_password_confirmation: confirmPassword,
        };
        resp = await axios.post(`${BASE_URL}/changePassword`, requestFirstLogin, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRedirectMessage("Success!<br />You will be redirected to the login page.");
        setRedirecting(true);

        setTimeout(() => {
          navigate(RoutesPath.Signin.path);
        }, 2000);
      } else {
        const request = {
          token: params.token,
          email,
          password,
          password_confirmation: confirmPassword,
        };
        const resp = await axios.post(`${BASE_URL}/reset-password`, request);
        const token = resp.data.token;
        const userName = resp.data.userName;
        saveToken(token);
        saveUserName(userName);
        setRedirectMessage("Success!<br />You will be redirected to the login page.");
        setRedirecting(true);

        setTimeout(() => {
          navigate(RoutesPath.Signin.path);
        }, 2000);
      }
    } catch (error) {
      console.error('Error while resetting password:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("There was an error resetting the password, please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCapsLock = (e) => {
    if (e.getModifierState("CapsLock")) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500" style={{ minHeight: '400px' }}>
              {redirecting ? (
                 <Alert
                 variant="success"
                 onClose={() => setRedirecting(false)}
                 className="mb-3"
                 style={{
                   padding: '5px 10px',
                   margin: '0 auto',
                   maxWidth: '400px',
                   fontSize: '14px',
                   display: 'flex',
                   justifyContent: 'center',
                   alignItems: 'center',
                   textAlign: 'center',
                 }}
               >
                 <span dangerouslySetInnerHTML={{ __html: redirectMessage }} />
               </Alert>

                ) : (
                message && (
                    <Alert
                      variant="info"
                      className="mb-3"
                      style={{
                        padding: '5px 10px',
                        margin: '0 auto',
                        maxWidth: '400px',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      {message}
                    </Alert>
                  )
                )}
                {errorMessage && (
                  <Alert
                  variant="danger"
                  className="mb-3"
                  style={{ padding: '5px 10px', margin: '0 auto', maxWidth: '400px', fontSize: '14px' }}
                  >
                    {errorMessage}
                  </Alert>
                )}
                <h6 className="mb-4 text-center">Reset Password</h6>
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          updatePasswordRequirements(e.target.value);
                          setPasswordsMatch(e.target.value === confirmPassword);
                        }}
                        placeholder="New Password"
                        minLength={8}
                        onKeyDown={handleCapsLock}
                        onKeyUp={handleCapsLock}
                      />
                    </InputGroup>
                        {(missingRequirements.minLength || missingRequirements.number || missingRequirements.uppercase || missingRequirements.specialChar) && (
                        <ul style={{ fontSize: "0.6rem" }}>
                            {missingRequirements.minLength && <li><small>Password must be between 8 and 12 characters.</small></li>}
                            {missingRequirements.number && <li><small>Password must contain at least one number.</small></li>}
                            {missingRequirements.uppercase && <li><small>Password must contain at least one uppercase letter.</small></li>}
                            {missingRequirements.specialChar && <li><small>Password must contain at least one special character.</small></li>}
                        </ul>
                        )}
                  </Form.Group>

                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          setPasswordsMatch(password === e.target.value);
                        }}
                        placeholder="Confirm New Password"
                        minLength={8}
                        onFocus={() => setConfirmPasswordTouched(true)}
                        onKeyDown={handleCapsLock}
                        onKeyUp={handleCapsLock}
                      />
                    </InputGroup>
                    {confirmPasswordTouched && !passwordsMatch && (
                      <div className="text-danger">
                        <span style={{ fontSize: '12px' }}><strong>Passwords do not match.</strong></span>
                        </div>
                    )}
                    {isCapsLockOn && (
                    <div className="mb-3 text-primary">
                      <span style={{ fontSize: '12px' }}><strong>Warning: Caps Lock is ON!</strong></span>
                    </div>
                  )}
                  </Form.Group>
                  <div className="d-grid">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loading || missingRequirements.minLength || missingRequirements.number || missingRequirements.uppercase || missingRequirements.specialChar || !passwordsMatch}
                    >
                      {loading ? 'Resetting...' : 'Reset Password'}
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
